import * as actionTypes from './actionTypes';
import axios from 'axios';
import { API_URL } from '../../utils/api';
import { toastr } from 'react-redux-toastr';
import {getUserInfo} from './user';

export const authReset=(error)=>{
  if(error===undefined){
    toastr.success("Activation email","Activation email sent successfully");
  }else{
    toastr.error('Email validation',error);
  }
  
    return {
      type:actionTypes.AUTH_RESET
    }
}
export const authForgetPassword=(error,callback)=>{
  if(error===undefined){
    toastr.success("Forget password email","Password email sent successfully");
    callback();
  }else{
    toastr.error('Forget password email',error);
  }
  
    return {
      type:actionTypes.AUTH_RESET
    }
}
export const autoConfirmPassword=(error)=>{
  if(error===undefined){
    toastr.success("Password change","Password changed successfully");
  }else{
    toastr.error('Password change',error);
  }
  
    return {
      type:actionTypes.AUTH_RESET
    }
}

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userId, email,expires) => {

  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
    email: email,
    expires:expires
  };
};


export const authFailed = (error) => {
  toastr.error('Error', error.message!==undefined ? error.message:error);
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };

};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('userId');
  localStorage.removeItem('email');

  return {
    type: actionTypes.AUTH_LOGOUT
  }
};


export const auth = (username, password) => {
  return dispatch => {
    dispatch(authStart());
    const authData = {
      username: username,
      password: password,
    };

    axios.post(`${API_URL}/login`, authData)
      .then(res => {
        console.log(res.data);
        const expirationDate = new Date(new Date().getTime() + res.data.expiresIn);

        localStorage.setItem('token', res.data.token);
        localStorage.setItem('expirationDate', expirationDate);
        localStorage.setItem('email', res.data.email);
        localStorage.setItem('userId', res.data.uId);

        dispatch(authSuccess(res.data.token, res.data.uId, res.data.email,expirationDate));
        dispatch(getUserInfo(res.data.token,res.data.uId));
       // dispatch(gettingTransactions(res.data.token,res.data.uId))


      }).catch(err => {
        console.log(err.response)
      if(err.response){
        dispatch(authFailed(err.response.data.error));
      }
      else{
        dispatch(authFailed(err.response))
      }

    });
  };
};


export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      if (expirationDate <= new Date()) {
       dispatch(logout())
      } else {
        const userId = localStorage.getItem('userId');
        const email = localStorage.getItem('email');
        dispatch(authSuccess(token, userId,email,expirationDate));
        dispatch(getUserInfo(token,userId));
        //dispatch(gettingTransactions(token,userId));
       // dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime())/ 1000) )
      }

    }
  };
};

  
  export const sendvalidationCodeagain=(username)=>{
    return dispatch => {
      dispatch(authStart());
      const authData = {
        username: username
      };
  
      axios.post(`${API_URL}/resendActivationemail`, authData)
        .then(res => {
          
 //         dispatch(getUserInfo(res.data.token,res.data.uId));
         // dispatch(gettingTransactions(res.data.token,res.data.uId))
          dispatch(authReset(res.data.error));
  
        }).catch(err => {
          console.log(err.response)
        if(err.response){
          dispatch(authFailed(err.response.data.error));
        }
        else{
          dispatch(authFailed(err.response))
        }
  
      });
    };
  }

  export const sendforgetemailagain=(email,callback)=>{
    return dispatch => {
      dispatch(authStart());
      const authData = {
        email: email
      };
  
      axios.post(`${API_URL}/users/sendPasswordEmail`, authData)
        .then(res => {
          
 //         dispatch(getUserInfo(res.data.token,res.data.uId));
         // dispatch(gettingTransactions(res.data.token,res.data.uId))
          dispatch(authForgetPassword(res.data.error,callback));
  
        }).catch(err => {
          console.log(err.response)
        if(err.response){
          dispatch(authFailed(err.response.data.error));
        }
        else{
          dispatch(authFailed(err.response))
        }
  
      });
    };
  }

  export const changePassword=(password,key)=>{
    return dispatch => {
      dispatch(authStart());
      const authData = {
        password: password,
        key:key
      };
  
      axios.post(`${API_URL}/changePassword`, authData)
        .then(res => {
          
 //         dispatch(getUserInfo(res.data.token,res.data.uId));
         // dispatch(gettingTransactions(res.data.token,res.data.uId))
          dispatch(autoConfirmPassword(res.data.error));
  
        }).catch(err => {
          console.log(err.response)
        if(err.response){
          dispatch(authFailed(err.response.data.error));
        }
        else{
          dispatch(authFailed(err.response))
        }
  
      });
    };
  }