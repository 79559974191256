import * as actionTypes from '../actions/actionTypes';

const initialState = {
  user: {
    createdAt: null,
    date_registration: null,
    email: null,
    id: null,
    last_failed_logged: null,
    last_present_logged: null,
    last_successful_logged: null,
    login: null,
    name: null,
    password: null,
    surname: null,
    updatedAt: null,
  },
  wallet: {
    balance: 0,
    deposited_balance: 0,
    withdrawn_balance: 0,
  },
  role:{

  },
  permissions:[

  ],
  transactions:[],
  transactionsLoaded:false,
  error:null,
  loading: false,
};

const homePageStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const gettingUserSuccess = (state, action) => {
  return {
    ...state,
    user: action.user,
    wallet: action.wallet,
    permissions:action.permissions,
    transactions:action.transactions,
    role:action.role,
    error: null,
    loading: false,
  };
};

const logoutUserInfo = (state,action)=>{
  return{
    ...state,
    user: {
      createdAt: null,
      date_registration: null,
      email: null,
      id: null,
      last_failed_logged: null,
      last_present_logged: null,
      last_successful_logged: null,
      login: null,
      name: null,
      password: null,
      surname: null,
      updatedAt: null,
    },
    wallet: {
      balance: 0,
      deposited_balance: 0,
      withdrawn_balance: 0,
    },
    transactions:[],
    error:null,
    loading: false,
  }
}



const gettingTransactionsStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const gettingTransactionsSuccess = (state,action)=>{
  return{
    ...state,
    transactions:action.transactions,
    loading:false,
    transactionsLoaded:true
  }
};

const gettinTransactionFailed = (state,action)=>{
  return{
    ...state,
    loading:false,
    transactionsLoaded:true,
    error:action.error
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETTING_USERINFO_START:
      return homePageStart(state, action);
    case actionTypes.GETTING_USER_SUCCESS:
      return gettingUserSuccess(state,action);
    case actionTypes.LOGOUT_USER_INFO:
      return logoutUserInfo(state,action);
    case actionTypes.GETTING_TRANSACTIONS_START:
      return gettingTransactionsStart(state,action);
    case actionTypes.GETTING_TRANSACTIONS_SUCCESS:
      return gettingTransactionsSuccess(state,action);
    case actionTypes.GETTING_TRANSACTIONS_FAILED:
      return gettinTransactionFailed(state,action);
    default:
      return state;
  }
};

export default reducer;
