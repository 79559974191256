import React, { useEffect } from 'react';
import {  useFormik } from 'formik';
import { TextField,Switch,Typography } from '@material-ui/core';
import * as yup from 'yup'; // for everything
import { useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../../components/Button/Button';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import Select from '@mui/material/Select';
import axios from 'axios';
import { API_URL } from '../../../utils/api';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { handleSwitchChange } from '../../../utils/helpers';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {Grid} from '@mui/material';
const AddNewTask = (props) => {
  let token = localStorage.getItem('token');
  const [packages,setpackages]=useState([]);
  const [alldetails, setAlldetails] = useState(props.obj.taskdetails);

  const handleAdddetails = () => {
    const values = [...alldetails];
    values.push({
      name: "", url:""
    });
    setAlldetails(values);
  };

  const handleRemovedetails = (index) => {
    if(alldetails.length>1){
    const values = [...alldetails];
    values.splice(index, 1);
    setAlldetails(values);
    }
  };

  const handleInputChange = (index, event,name) => {
    const values = [...alldetails];
 //   const updatedValue = event.target.name;
    values[index][name] = event.target.value;
    setAlldetails(values);
  };

  
  const formik = useFormik({
    isInitialValid:props.obj.id===0 ? false:true,
    validateOnMount:props.obj.id===0 ? false:true,
    initialValues:props.obj,
    validationSchema: yup.object().shape({
      title:yup
      .string("Enter title")
      .required("title is required"),
      duration: yup
      .number("Enter duration")
      .max(24,'value must be less than or equal to 24')
      .min(1,"Value must be greator than or equal to 1")
      .required('Value is required. Only numeric value accepted'),
      id_package:yup.array()
              .required("Select package"),
      status:yup.string("Select status").required("Status is required"),
      taskdetails:yup.array().of(
        yup.object().shape({
          name: yup.string().required("Name is required"),
          url: yup.string().required("Url is required"),
        })
      )
    }),

    onSubmit: (values, actions) => {
      const payload = {
        ...values,
        id_package: values.id_package.map(t => t.value),
      };
      //  console.log(customParams);
        onFormSubmit(payload,actions)
    }
  });
  const onFormSubmit = (data, customParams, form) => {
    // var formdata=new FormData();    
    // var keys=Object.keys(data);
    // keys.forEach((v,i)=>{
    //   formdata.append(v,data[v]);
    // })
    customParams.onSave(data,form);

    // async request which may result error
    try {
      // await fetch()
    } catch (e) {
      // handle your error
    }
  }
  useEffect(() => {
    console.log(alldetails);
    formik.values.taskdetails=alldetails;
    axios.get(`${API_URL}/getactivepackages`, {
      headers: {
        Authorization: `JWT ${token}`, 'Content-Type': 'application/json'
      },
    })
      .then(async (res) => {
        setpackages(res.data.data);
      });
  }, [props.obj,token,alldetails]);
 
  return (
    <div className="DataPAGE__form">
      <form onSubmit={form => { onFormSubmit(formik.values,props, form); }}>
      <TextField disabled={props.isView} type='hidden' id="id" name="id" value={formik.values.id} />

        <TextField disabled={props.isView} 
          fullWidth 
          type="text"
          label="Title"
          id="title"
          name="title"
          required
          inputProps={{ min: 0 }}
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />  
     
        <TextField disabled={props.isView} 
          fullWidth 
          type="number"
          label="Duration"
          id="duration"
          name="duration"
          required
          inputProps={{ min: 0 }}
          value={formik.values.duration}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.duration && Boolean(formik.errors.duration)}
          helperText={formik.touched.duration && formik.errors.duration}
        />  
<br/><br/>
<FormControl fullWidth>
          <InputLabel id="id_package">Package</InputLabel>
          <Select
            multiple={true}
            labelId="id_package"
            id="id_package"
            name='id_package'
            label="Select Package"
            disabled={props.isView}
            value={formik.values.id_package}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.id_package && Boolean(formik.errors.id_package)}
          >
          {packages.map((v,i) => (
          <MenuItem key={i} value={v.id}>
            {v.name}
          </MenuItem>
        ))}
          </Select>
        </FormControl>
        <br/>
            
        <FormControlLabel  label="status" control={<Switch value={formik.values.status} disabled={props.isView}  onChange={(e)=>handleSwitchChange(e,formik,'Enable','Disable')}  defaultChecked={formik.values.status==='Enable'? true:false} inputProps={{ 'aria-label': 'Status' }} />}  />
     
        <br />
        <div className='padding-8'>
        <button className='btn' style={{float:'right'}}
                    type="button"
                    onClick={() => handleAdddetails()}
                  > 
                    Add a link
                  </button>
                  </div>
        <Grid className='padding-12'  container spacing={{ xs: 1 , md: 2 }} columns={{ xs: 1, sm: 5, md: 12 }}>
     
        {formik.values.taskdetails.length > 0 && (
                <>
                  {formik.values.taskdetails.map((field, index) => (
      
                    <>
             <Grid item xs={1} md={5} sm={2}>   
        <TextField disabled={props.isView} 
         fullWidth 
         type="text"
         label="Name"
         id={`taskdetails[${index}].name`}
         name={`taskdetails[${index}].name`}
         value={formik.values.taskdetails?.[index]?.name}
         onChange={(e)=>handleInputChange(index,e,"name")}
         onBlur={formik.handleBlur}
         required
        //  onChange={(event) =>
        //   handleInputChange(index, event)
        // }
         
        //  onBlur={formik.handleBlur(`taskdetails[${index}].name`)}
         error={ formik.touched.taskdetails?.[index]?.name && Boolean(formik.errors.taskdetails?.[index]?.name)}
         helperText={formik.touched.taskdetails?.[index]?.name && formik.errors.taskdetails?.[index]?.name}
       />  
  </Grid>
  <Grid item xs={1} md={5} sm={2}> 
             <TextField disabled={props.isView} 
          fullWidth 
          type="text"
          label="Url"
          id={`taskdetails[${index}].url`}
          name={`taskdetails[${index}].url`}
          value={formik.values.taskdetails?.[index]?.url}
          required
          onChange={(e)=>handleInputChange(index,e,"url")}
          onBlur={formik.handleBlur}
        //  onChange={(event) =>
        //   handleInputChange(index, event)
        // }
        //   onBlur={formik.handleBlur(`taskdetails[${index}].url`)}
          error={ formik.touched.taskdetails?.[index]?.url && Boolean(formik.errors.taskdetails?.[index]?.url)}
          helperText={formik.touched.taskdetails?.[index]?.url && formik.errors.taskdetails?.[index]?.url}
  />  
         </Grid>
         <Grid item xs={1} md={2} sm={1} style={{marginTop:'2%',float:'right'}}> 
             <button className='btn' style={{paddingTop:'3%'}}
                            type="button"
                            onClick={() => handleRemovedetails(index)}
                          >
                            <DeleteOutlinedIcon/>
                          </button>
                          </Grid>
         </>
         
          ))}</>
          )}
       
        </Grid>
      

     
        <Typography align='right'>
        <Button  type="submit" disabled={!formik.isValid || props.isView}><SaveOutlined /> Save</Button>
        </Typography>
      </form>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    user: state.user.user,
    wallet: state.user.wallet,
    transactions: state.user.transactions,
    permissions:state.user.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddNewTask);