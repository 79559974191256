import React, {  useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { getToaster } from '../../utils/helpers';
import { Button } from 'react-materialize';
import { API_URL } from '../../utils/api';
import axios from 'axios';
import { getPagePermission } from '../../utils/helpers';
import WalletsList from './WalletList/WalletsList';
import AddWallet from './AddWallet/AddWallet';
const WalletsPage=(props)=>{
  let token = localStorage.getItem('token'); 
  const [isView,]=useState(false);
  const [activePage,setactivePage]=useState('WALLET_LIST');

  const [obj,setObj]=useState({
    id:0,
    balance:0,
    withdrawn_balance:0,
    deposited_balance:0,
    deduction:0
  });
  const UpdateWallet= async (values,e)=>{
    e.preventDefault();
    console.log(values);
   props.onLoadingStart();

    return  axios.post(`${API_URL}/wallets/update`,values, {
      headers: {
        Authorization: `JWT ${token}`,'Content-Type':'application/json'
      },
    })
      .then(async(res) => {     
        let data=res.data;  
         props.onLoadingStop();
         if (data.success) {
           getToaster('Success','Wallet update status', data.message);
           
           setactivePage('WALLET_LIST');
         }else{
           getToaster('Error',"Wallet updating error", data.message);
         }
      });
  }

 
  const getWalletUpdatePage=(row)=>{
    console.log(row);
    setactivePage("REQUEST_WALLET_UPDATE");
    setObj(row);
  }
  
 


  

  useEffect(()=>{
    if (token == null) {
       props.history.replace('/login');
    }
 
    const changePage = (page) => {
      setactivePage(page);
      if(activePage==="WALLET_LIST"){
      //  setisAddDisable(true);
      }
      else{
        setObj({...obj,
          id:0,
          amount_money:0,
        });
     //   setisAddDisable(false);
      }
    };
    let permissionData=getPagePermission(props.permissions,"wallets");
    console.log(permissionData); 
      changePage(activePage);
  },[activePage,props.history,props.permissions,token]);


  
    return (
      <>
        {props.user.id ?
     <div className="DataPAGE">
     <div className="DataPAGE__add">
              <nav>
                <ul>
                {/* <li><Button onClick={() => setactivePage('REQUEST_WALLET_UPDATE')}>Update Wallet/deduction</Button></li> */}
                  <li><Button onClick={() => setactivePage('WALLET_LIST')}>Wallet List</Button></li>
                </ul>
              </nav>
            </div>
            {activePage === 'WALLET_LIST' ?
  <WalletsList   onWalletUpdate={getWalletUpdatePage} /> :  <AddWallet isView={isView} onSave={UpdateWallet} obj={obj}   />}
          </div> : null}
      </>
    );
}



const mapDispatchToProps = dispatch => {
  return {
    onLoadingStart: () => dispatch(actions.startLoading()),
    onLoadingStop: () => dispatch(actions.stopLoading()),
    
  };
};

const mapStateToProps = state => {
  return {
    user: state.user.user, 
    permissions:state.user.permissions
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletsPage);
