import * as actionTypes from '../actions/actionTypes';

const initialState = {
  deleting: false,
  msg:'',
  deleteUrl:'',
  deleteId:0,
  deleteBtn:true,
  onDeleteSuccess:()=>{}
};

const deletingStart = (state, action) => {
  return {
    ...state,
    deleting: true,
    msg:action.msg,
    deleteUrl:action.deleteUrl,
    deleteId:action.deleteId,
    deleteBtn:true,
    onDeleteSuccess:action.onDeleteSuccess
  };
};
const modifydeleteMsg = (state, action) => {
  return {
    ...state,
    msg:action.msg,
    deleteBtn:false
  };
};


const deletingStop = (state, action) => {
  return {
    ...state,
    deleting: false,
    msg:'',
    deleteUrl:'',
    deleteId:0,
    deleteBtn:true,
    onDeleteSuccess:()=>{}
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_DELETING:
      return deletingStart(state,action);
    case actionTypes.STOP_DELETING:
      return deletingStop(state,action);
   case actionTypes.START_DELETE_RESPONSE:
      return modifydeleteMsg(state,action);
    default:
      return state;
  }
};

export default reducer;

