import React, {  useEffect,useState } from 'react';
import './RegisterPage.scss';
import heroImg from '../../assets/images/Mlm-hero-nooverlay.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../utils/api';
import RegisteredUser from './RegisteredUserInfo/RegisteredUser';
import RegisterForm from './RegisterForm/RegisterForm';
import ValidatedUser from './RegisteredUserInfo/ValidatedUser';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { getToaster } from '../../utils/helpers';
import { useParams } from 'react-router-dom';
const RegisterPage=(props)=>{
  const {reference_key,validationcode}=useParams();
 console.log(reference_key);
 console.log(validationcode);
  let [newUserData,setnewUserData]=useState({
      username: '',
      email: '',
      name: ''
    });

  let [isRegisterSuccess,setisRegisterSuccess]=useState(false);

    useEffect(()=>{

    },[])




  

  const registerUser = (values, e) => {
    e.preventDefault();

    console.log(values)
    props.onStartLoading();
    axios.post(`${API_URL}/register`, values)
      .then(res => {
        console.log(res.data);
        props.onStopLoading();
        setisRegisterSuccess(true);
        setnewUserData(res.data);
      })
      .catch(err => {
        props.onStopLoading();
        console.log(err.response.data);
        getToaster('Error','User registration status', err.response.data.error);
      });
    console.log(values);
  };


    const styles = {
      background: `linear-gradient(
      rgba(0, 64, 128, 0.5),
      rgba(0, 64, 128, 0.9) 
      
    ),url(${heroImg}) `,
    };


    const displayForm = validationcode!==undefined ? <ValidatedUser onStartLoading={props.onStartLoading} onStopLoading={props.onStopLoading}  validationcode={validationcode} /> : isRegisterSuccess ? <RegisteredUser user={newUserData}/> :
      <RegisterForm reference_key={reference_key} registerUser={registerUser}/>;


    return (
      <>
        <div className="RegisterPage">
          <div className="header" style={styles}>
            <h1 style={{color:'#fff'}}>Register account</h1>
          </div>
          <div className="content">
            {displayForm}
          </div>
          <footer>
            Already have account? <span><Link to="/login">Sign In</Link></span>
          </footer>
        </div>
      </>
    );
  
};

const mapDispatchToProps = dispatch =>{
  return{
    onStartLoading:()=>dispatch(actions.startLoading()),
    onStopLoading:()=>dispatch(actions.stopLoading())
  }
};

export default connect(null, mapDispatchToProps)(RegisterPage);
