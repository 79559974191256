import React from 'react'
import { connect } from 'react-redux';
import CustomDataGrid from '../../../components/DataGrid/CustomDataGrid';
import * as actions from '../../../store/actions';
import { useEffect } from 'react';
import { Button } from 'react-materialize';
const WalletList=(props)=>{
 
  const columns = [
    {
      field: 'id', headerName: 'ID', width: 70
    },
    {
      field: "user.username",
      headerName: "Username",
      width: 200,
      valueGetter: (params) => {
        return params.row.user!=null ? params.row.user.username:"N/A";
      }
    },
    {
      field: "user.name",
      headerName: "Name",
      width: 200,
      valueGetter: (params) => {
        return params.row.user!=null ? params.row.user.name:"N/A";
      }
    },
    {
      field: 'balance', headerName: 'Balance', width: 200
    },
    {
      field: 'deposited_balance', headerName: 'Deposited Balance', width: 200
    },
    {
      field: 'withdrawn_balance', headerName: 'Withdrawn Balance', width: 200
    },
    {
      field: 'deduction', headerName: 'Deduction', width: 200
    },
    {
      field:'DeductionRequest',headerName:'Add Deduction',width:200,
      renderCell: (params) =>params.row.balance>0 ? <Button onClick={()=>props.onWalletUpdate(params.row)}>Process Transaction</Button>:<span>Out of balance</span>
    }
    // {
    //   field:'name',
    //   headerName:"Package",
    //   width: 500,
    //   renderCell: (params) =><PackageCard packageRecord={params.row} onPurchase={()=>props.onPurchase(params.row)} alreadypurchased={params.row.usertransactions.length || params.row.transactions.length > 0} image="" />   
    
    // }
    // ,
    // {
    //   field: 'email', headerName: 'Email Address', width: 200
    // }
  ]
  useEffect(()=>{
   // props.homePageStart();
  },[]);
  return (
    <>
      <CustomDataGrid rowHeight={50}  url="wallets" deleteUrl="/wallets/delete" onEdit={props.onEdit} serverListenerUrl="/wallets"  columns={columns} />
</>
  )
}
const mapStateToProps = state => {
  return {
    user: state.user.user,
    bill: state.user.bill,
    transactions: state.user.transactions,
    permissions:state.user.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGettingTransaction: (token, uId) => dispatch(actions.gettingTransactions(token, uId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WalletList);
