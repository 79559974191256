import React from 'react'
import { connect } from 'react-redux';
import CustomDataGrid from '../../../components/DataGrid/CustomDataGrid';
import * as actions from '../../../store/actions';
import { useEffect } from 'react';
const TaskList=(props)=>{
 
  const columns = [
    {
      field: 'id', headerName: 'ID', width: 70
    },
    {
      field: 'title', headerName: 'Title', width: 200
    },
    {
      field: 'duration', headerName: 'Duration', width: 200
    },
    {
      field: 'status', headerName: 'Status', width: 200
    },
    {
      field:'Action',headerName:'Action',width:200
    }
  ]
  useEffect(()=>{
   // props.homePageStart();
  },[]);
  return (
    <>
      <CustomDataGrid rowHeight={50}  url="tasks" deleteUrl="/tasks/delete" onEdit={props.onEdit} serverListenerUrl="/tasks"  columns={columns} />
</>
  )
}
const mapStateToProps = state => {
  return {
    user: state.user.user,
    bill: state.user.bill,
    transactions: state.user.transactions,
    permissions:state.user.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGettingTransaction: (token, uId) => dispatch(actions.gettingTransactions(token, uId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
