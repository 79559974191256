import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { TextField,Typography } from '@material-ui/core';
import * as yup from 'yup'; // for everything
import { connect } from 'react-redux';
import Button from '../../../components/Button/Button';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import WalletCard from '../../../components/Card/WalletCard';

const AddWallet = (props) => {
  const formik = useFormik({
    isInitialValid:false,
    validateOnMount:false,
    initialValues:{
      id:props.obj.id,
      deduction:0
    },
    validationSchema: yup.object().shape({
      id:yup.number(),
      deduction: yup
      .number("Enter deduction amount")
      .max(props.obj.balance,`Deduction amount cannot be greator than ${props.obj.balance}`)
      .min(0,"Value must be greator than or equal to 0")
      .required('Value is required. Only numeric value accepted')
    }),

    onSubmit: (values, actions) => {
      //  console.log(customParams);
        onFormSubmit(values,actions)
    }
  });
  const onFormSubmit = (data, customParams, form) => {
    var formdata=new FormData();    
    var keys=Object.keys(data);
    keys.forEach((v,i)=>{
      formdata.append(v,data[v]);
    })
    customParams.onSave(formdata,form);

    // async request which may result error
    try {
      // await fetch()
    } catch (e) {
      // handle your error
    }
  }
  useEffect(() => {
  }, [props.obj]);

  return (
    <div className="DataPAGE__form">
      <form onSubmit={form => { onFormSubmit(formik.values,props, form); }}>
      <TextField disabled={props.isView} type='hidden' id="id" name="id" value={formik.values.id} />
        <WalletCard balance={props.obj.balance} />
        <br/>
        <TextField disabled={props.isView} 
          fullWidth 
          type="number"
          id="deduction"
          name="deduction"
          label="Deduction"
          value={formik.values.deduction}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.deduction && Boolean(formik.errors.deduction)}
          helperText={formik.touched.deduction && formik.errors.deduction}
        />  
     
        <br/>
        <Typography align='right'>
        <Button  type="submit" disabled={!formik.isValid || props.isView || (props.balance<=0)}><SaveOutlined /> Update/Add deduction</Button>
        </Typography>
      </form>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    user: state.user.user,
    wallet: state.user.wallet,
    transactions: state.user.transactions,
    permissions:state.user.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddWallet);