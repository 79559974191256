import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { TextField,Switch, Typography  } from '@material-ui/core';
import * as yup from 'yup'; // for everything
import { useState } from 'react';
import Button from '../../../components/Button/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import { handleSwitchChange } from '../../../utils/helpers';
import FileUploadButton from '../../../components/Button/FileInputButton';
import { isValidFileType,MAX_FILE_SIZE } from '../../../utils/helpers';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
const AddNewPackage = (props) => {
  const [initialValues]=useState(props.obj);
  const formik = useFormik({
  isInitialValid:props.obj.id===0 ? false:true,
  validateOnMount:props.obj.id===0 ? false:true,
  initialValues:initialValues,
  validationSchema:yup.object({
   
    name: yup
      .string('Enter package name')
      .required('Name is required'),
    price: yup
    .number()
    .min(0,"Price must be greator than or equal to 0")
    .required('Price is required. Only numeric value accepted'),
    expirydays: yup
    .number('Enter expiry days')
    .min(0,"Expiry days must be greator than or equal to 0")
    .required('Expiry days are required. Only numeric value accepted'),
    reward: yup
    .number('Enter reward')
    .min(0,"Reward must be greator than or equal to 0")
    .required('Reward is required. Only numeric value accepted'),
    referencereward: yup
    .number('Enter reference reward')
    .min(0,"Reference reward must be greator than or equal to 0")
    .required('Reference reward is required. Only numeric value accepted'),
    status: yup
    .string('Enter status')
    .required('Status is required'),
    package_image_file: yup
    .mixed()
    .required("Please upload the banner")
    .test("is-valid-type", "Not a valid image type",
      value => { console.log(value); return isValidFileType(value && value.name.toLowerCase(), "package_image_file"); })
    .test("is-valid-size", "Max allowed size is 10MB",
      value => value && value.size <= MAX_FILE_SIZE)
  }),
  
  onSubmit:(values, actions ) => {
  //  console.log(customParams);
  //  onFormSubmit(values,actions)
  }});
  const onFormSubmit =(data,customParams,form) => {

    var formdata=new FormData();    
    var keys=Object.keys(data);
    keys.forEach((v,i)=>{
      formdata.append(v,data[v]);
    })
    customParams.onSave(formdata,form);


    // async request which may result error
    try {
      // await fetch()
    } catch (e) {
      // handle your error
    }
  }
  useEffect(()=>{
    
  },[]);
  
  return (
    <div className="DataPAGE__form">
          <form onSubmit={form => { onFormSubmit(formik.values,props, form); }}>
            <TextField disabled={props.isView} type='hidden' id="id" name="id" value={formik.values.id} />
           
            <TextField disabled={props.isView} 
          fullWidth
          id="name"
          name="name"
          required
          label="Name"
          inputProps={{ min: 0 }} 
          type="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
                <TextField disabled={props.isView} 
          fullWidth 
          type="number"
          label="Price"
          id="price"
          name="price"
          required
          inputProps={{ min: 0 }}
          value={formik.values.price}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={formik.touched.price && formik.errors.price}
        />
          <TextField disabled={props.isView} 
          fullWidth 
          type="number"
          label="Expiry Days"
          id="expirydays"
          required
          name="expirydays"
          inputProps={{ min: 0 }}
          value={formik.values.expirydays}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.expirydays && Boolean(formik.errors.expirydays)}
          helperText={formik.touched.expirydays && formik.errors.expirydays}
        />
          <TextField disabled={props.isView} 
          fullWidth 
          type="number"
          label="Reward"
          required
          id="reward"
          name="reward"
          inputProps={{ min: 0 }}
          value={formik.values.reward}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.reward && Boolean(formik.errors.reward)}
          helperText={formik.touched.reward && formik.errors.reward}
        />
          <TextField disabled={props.isView} 
          fullWidth 
          required
          type="number"
          label="Reference reward"
          id="referencereward"
          name="referencereward"
          inputProps={{ min: 0 }}
          value={formik.values.referencereward}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.referencereward && Boolean(formik.errors.referencereward)}
          helperText={formik.touched.referencereward && formik.errors.referencereward}
        />
       {FileUploadButton(formik, 'package_image_file', 'Upload package banner')}

        <FormControlLabel  label="status" control={<Switch value={formik.values.status} disabled={props.isView}  onChange={(e)=>handleSwitchChange(e,formik,'Enable','Disable')}  defaultChecked={formik.values.status==='Enable'? true:false} inputProps={{ 'aria-label': 'Status' }} />}  />
        


        {/* <FormControlLabel control={<Switch id='status' defaultValue={formik.values.status} onChange={(e)=>handleSwitchChange(e,'Enable','Disable')} name="status" value={formik.values.status}  />} label="Status" /> */}
        <br/>
        <Typography align='right'>
            <Button  type="submit" disabled={!formik.isValid || props.isView }><SaveOutlined />  Save Package</Button>
            </Typography>
          </form>
    </div>
  );
};

export default AddNewPackage;
