import React from 'react'
import { connect } from 'react-redux';
import CustomDataGrid from '../../../components/DataGrid/CustomDataGrid';
import * as actions from '../../../store/actions';
import { useEffect } from 'react';
import PackageCard from '../../../components/Card/PackageCard';
import { API_URL } from '../../../utils/api';
const UserPackagesList=(props)=>{

  const columns = [
    // {
    //   field: 'id', headerName: 'ID', width: 70
    // },
    // {
    //   field: 'name', headerName: 'Name', width: 200
    // }
    {
      field:'name',
      headerName:"Package",
      flex:1,
      renderCell: (params) =><PackageCard transaction={params.row.transactions.length > 0 ? params.row.transactions[0].id:0} packageRecord={params.row} onDifferencePay={()=>{props.onDifferencePay(params.row,params.row.transactions.length > 0 ? params.row.transactions[0].id:0)}} onPurchase={()=>props.onPurchase(params.row)}  differenceToPay={params.row.transactions.length > 0 ?   params.row.transactions[0].amount_money+params.row.transactions[0].remaining_amount_money<params.row.price:false} alreadypurchased={params.row.userpackages.length || params.row.transactions.length > 0} image={`${API_URL}/packagebanners/${params.row.package_image_file}`} />   
    
    }
    // ,
    // {
    //   field: 'email', headerName: 'Email Address', width: 200
    // }
  ]
  useEffect(()=>{
   // props.homePageStart();
  },[]);
  return (
    <>
      <CustomDataGrid rowHeight={180}  url="userpackages" deleteUrl="/userpackages/delete" onEdit={props.onEdit} serverListenerUrl="/userpackages"  columns={columns} />
</>
  )
}
const mapStateToProps = state => {
  return {
    user: state.user.user,
    bill: state.user.bill,
    transactions: state.user.transactions,
    permissions:state.user.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGettingTransaction: (token, uId) => dispatch(actions.gettingTransactions(token, uId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserPackagesList);
