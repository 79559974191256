import React, { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { API_URL } from '../../../utils/api';
import ReactPlayer from 'react-player';
import { getToaster } from '../../../utils/helpers';
import * as actions from '../../../store/actions';
import '../UserTasksPage.scss';
const AddNewUserTask = (props) => {
  let token = localStorage.getItem('token');
  const [taskdetails,settaskdetails]=useState(props.obj)
  console.log(taskdetails);
  // let [paymentMethods, setpaymentMethods] = useState([]);
  // const formik = useFormik({
  //   isInitialValid:false,
  //   validateOnMount:false,
  //   initialValues:{
  //     id_paymentmethod:1,
  //     amount_money:0

  //   },
  //   validationSchema: yup.object().shape({
  //     id_paymentmethod:yup
  //     .string('Enter payment method')
  //     .required("Please select payment method"),
  //     amount_money: yup
  //     .number("Enter withdrawal requested amount")
  //     .max(props.wallet.balance,`Withdrawal amount cannot be greator than ${props.wallet.balance}`)
  //     .min(0,"Value must be greator than or equal to 0")
  //     .required('Value is required. Only numeric value accepted'),
  //     user_receiving_number:yup
  //     .string('Enter receiving phone number')
  //     .matches(
  //       /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
  //       "Receiving number is invalid"
  //     )
  //     .required('Receiving number is required. Only numeric value accepted'),
  //   }),

  //   onSubmit: (values, actions) => {
  //     //  console.log(customParams);
  //       onFormSubmit(values,actions)
  //   }
  // });
  // const onFormSubmit = (data, customParams, form) => {
  //   var formdata=new FormData();    
  //   var keys=Object.keys(data);
  //   keys.forEach((v,i)=>{
  //     formdata.append(v,data[v]);
  //   })
  //   customParams.onSave(formdata,form);

  //   // async request which may result error
  //   try {
  //     // await fetch()
  //   } catch (e) {
  //     // handle your error
  //   }
  // }
  // useEffect(() => {
  //   axios.get(`${API_URL}/paymentmethods`, {
  //     headers: {
  //       Authorization: `JWT ${token}`, 'Content-Type': 'application/json'
  //     },
  //   })
  //     .then(async (res) => {
  //       setpaymentMethods(res.data.data);
  //     });
  // }, [token,props.obj,props.wallet,formik.values]);
  useEffect(()=>{
   // taskdetails=taskdetails;
  //  console.log(props.obj);
  //  settaskdetails(props.obj);
  },[taskdetails]);
  const taskEnded=(id,index)=>{
     const newtaskdetails = [...taskdetails];
     newtaskdetails[index].isPlaying=false;
     if(newtaskdetails[index+1]){
     newtaskdetails[index+1].isPlaying=true;
     }
      console.log('onEnded');
      settaskdetails(newtaskdetails);
      axios.post(`${API_URL}/usertasks/add`,{id_taskdetail:id,id_task:props.taskId,id_package:props.packageId}, {
        headers: {
          Authorization: `JWT ${token}`,'Content-Type':'application/json'
        },
      })
        .then(async(res) => {     
          let data=res.data;  
           props.onLoadingStop();
           if (data.success) {
            if(data.message!==undefined){
             getToaster('Success','Task status', data.message);
             props.AddTask();
            }
           }else{
             getToaster('Error',"Task status", data.message);
           }
         
        });
  };
  return (
    <div className="DataPAGE__form">
      {/* <form onSubmit={form => { onFormSubmit(formik.values,props, form); }}> */}


      <form onSubmit={form => { }}>
        
             {taskdetails.length > 0 && (
   
              <div className="DataPage__form__Task">
   
 {taskdetails.map((x,i)=>(
  
    
    <ReactPlayer key={i} width={'100%'}  playing={x.isPlaying} onEnded={()=>taskEnded(x.id,i)} url={x.url} />
   ))}</div>
             )}

        {/* <Button  type="submit" disabled={!formik.isValid || props.isView || (props.balance<=0)}><SaveOutlined /> Request</Button> */}
      </form>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    user: state.user.user,
    wallet: state.user.wallet,
    transactions: state.user.transactions,
    permissions:state.user.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onLoadingStart: () => dispatch(actions.startLoading()),
    onLoadingStop: () => dispatch(actions.stopLoading()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddNewUserTask);