import React from 'react'
import EditRoadOutlinedIcon from '@mui/icons-material/EditRoadOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { connect } from 'react-redux';
import  * as actions from '../../store/actions';

const CustomCell=(props)=>{
    let html=[];
    let permission=props.permission;
    // if(permission.type.includes("All")){
    //     html.push(<button  onClick={()=>{props.onEdit(props.params.id)}}><EditRoadOutlinedIcon /></button> | <button><VisibilityOutlinedIcon /></button> |  <button onClick={()=>props.onDeleteStart(props.msg,props.deleteId,props.deleteUrl,props.onDeleteSuccess)}><DeleteForeverOutlinedIcon /></button>);
    // }else{      
     
     if((permission.type.includes("Edit")|| permission.type.includes("All")) && permission.menu.menudetails.filter(x=>x.name==="Edit").length>0){
      html.push(<button key={1}  onClick={()=>{props.onEdit(props.params.id,false)}}><EditRoadOutlinedIcon /></button>);
     }
     if((permission.type.includes("View")|| permission.type.includes("All")) && permission.menu.menudetails.filter(x=>x.name==="View").length>0){
       if(html.length>0){
        html.push(<span key={2} > | </span>);
       }
       html.push(<button key={3} onClick={()=>{props.onEdit(props.params.id,true)}}><VisibilityOutlinedIcon /></button>);
     }
     if((permission.type.includes("Delete")|| permission.type.includes("All")) && permission.menu.menudetails.filter(x=>x.name==="Delete").length>0){
      if(html.length>0){
        html.push(<span key={4 }> | </span>);
       }
       html.push(<button key={5} onClick={()=>props.onDeleteStart(props.msg,props.deleteId,props.deleteUrl,props.onDeleteSuccess)}><DeleteForeverOutlinedIcon /></button>);
  //   }
     
    }

  return (
    <div key={0}>
        {html}
    </div>
  );
}
const mapStateToProps = state => {
  return {
   
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onDeleteStart: (msg, deleteId,deleteUrl,onDeleteSuccess) => dispatch(actions.startDeleting(msg,deleteId,deleteUrl,onDeleteSuccess)),
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(CustomCell);
