import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { TextField, Typography  } from '@material-ui/core';
import * as yup from 'yup'; // for everything
import { useState } from 'react';
import Button from '../../../components/Button/Button';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import Box from '@mui/material/Box';
const AddNewUserComplaint = (props) => {
  const [initialValues]=useState(props.obj);
  const formik = useFormik({
  isInitialValid:props.obj.id===0 ? false:true,
  validateOnMount:props.obj.id===0 ? false:true,
  initialValues:initialValues,
  validationSchema:yup.object({
    id_transaction: yup
    .number()
    .required('Transaction ID is required'),
    description: yup
    .string('Enter status')
    .required('Status is required'),
  }),
  
  onSubmit:(values, actions ) => {
  //  console.log(customParams);
  //  onFormSubmit(values,actions)
  }});
  const onFormSubmit =(data,props,form) => {
    console.log(data);
    props.SaveComplaint(data,form);
    try {
      // await fetch()
    } catch (e) {
      // handle your error
    }
  }
  useEffect(()=>{
    console.log(props.adminResponse);
  },[]);
  
  return (
    <div className="DataPAGE__form">
           <form onSubmit={form => { onFormSubmit(formik.values,props, form); }}>
        
                <TextField disabled={props.isView} 
          fullWidth 
          type="number"
          label="Transaction"
          id="id_transaction"
          name="id_transaction"
          required
          inputProps={{ min: 0 }}
          value={formik.values.id_transaction}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.id_transaction && Boolean(formik.errors.id_transaction)}
          helperText={formik.touched.id_transaction && formik.errors.id_transaction}
        />
         
          <TextField disabled={props.isView} 
          fullWidth 
          type="text"
          label="Description"
          required
          multiline
          rows={4}
          id="description"
          name="description"
          inputProps={{ min: 0 }}
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />
        <br/>
        <br/>
        {props.isView &&  (<>  <Typography variant="body2" gutterBottom>
        Response : {props.adminResponse.response} 
        </Typography> <br></br>
        <Typography variant="body2" gutterBottom>
        Status : {props.adminResponse.status}
        </Typography></>)}
        <br/>
        <Typography align='right'>
            <Button  type="submit" disabled={!formik.isValid || props.isView }><SaveOutlined />  Save Complaint</Button>
            </Typography>
          </form>
    </div>
  );
};

export default AddNewUserComplaint;
