import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
const DeleteDialog=(props)=>{
  let token = localStorage.getItem('token');
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    props.stopDeleting();
  };
  React.useEffect(()=>{
       setOpen(props.deleting); 
  },[props.deleting])   

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.msg}
          </DialogContentText>
        </DialogContent>
        {props.deleteBtn &&
        <DialogActions>
          <Button onClick={()=>props.stopDeleting()}>No</Button>
          <Button onClick={()=>props.onConfirmDelete(token,props.deleteId,props.deleteUrl,props.onDeleteSuccess())}>Yes</Button>
        </DialogActions>
        }
      </Dialog>
    </React.Fragment>
  );
}
const mapStateToProps = state => {
    return {
        deleting:state.delete.deleting,
        deleteUrl:state.delete.deleteUrl,
        deleteId:state.delete.deleteId,
        msg:state.delete.msg,
        deleteBtn:state.delete.deleteBtn,
        onDeleteSuccess: state.delete.onDeleteSuccess
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
      onConfirmDelete: (token,id,url,onDeleteSuccess) => dispatch(actions.confirmDelete(token,id,url,onDeleteSuccess)),
      stopDeleting:()=>dispatch(actions.stopDeleting())
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);