import React from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './PackageCard.scss';

export default function PackageCard(props) {

  return (
    <Card  sx={Object.assign({ maxWidth: 700,display: 'flex',
      flexDirection: 'row' },{...props.style})}>
      <CardMedia
        
        sx={{ height: '100px',width:'100px' }}
        image={props.image}
        title="green iguana"
        component='img'
        style={{objectFit: 'contain',height:100,marginTop:8, padding:10,}} // specify styles
      />
      <CardContent className='packageCardContent'>
        <div>
        <Typography gutterBottom variant="h6" component="div">
          {props.packageRecord.name}
        </Typography>
        <Typography variant="body2" color="text.primary">
         Price    {props.packageRecord.price} 
        </Typography>
        <Typography variant="body2" color="text.secondary">
         Reward    {props.packageRecord.reward} Rupees per task
        </Typography>
        <Typography variant="body2" color="success.main">
        Valid For {props.packageRecord.expirydays} Days
        </Typography>
        </div>
        {props.purchaseAction===undefined 
       &&
      <CardActions>        
      {props.differenceToPay ?  <Button onClick={()=>props.onDifferencePay(props.packageRecord,props.transaction)}  size="small">Pay Difference</Button>: !props.alreadypurchased ?  <Button onClick={()=>props.onPurchase(props.packageRecord)} disabled={props.alreadypurchased} size="small">Purchase</Button>:  <Typography  variant="body2" color="success.main" noWrap={false} style={{ backgroundColor:"#f9f1c8",color:"#5e4f00", paddingTop:3, paddingBottom:3,paddingLeft:10,paddingRight:10,borderRadius:4,fontSize:13, }}>Purchased or Pending</Typography> }
      </CardActions>
      }
      </CardContent>
     
    </Card>
  );
}
