import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { TextField,Typography } from '@material-ui/core';
import * as yup from 'yup'; // for everything
import { useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../../components/Button/Button';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import Select from '@mui/material/Select';
import axios from 'axios';
import { API_URL } from '../../../utils/api';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import WalletCard from '../../../components/Card/WalletCard';
const AddNewUserTransaction = (props) => {
  let token = localStorage.getItem('token');
  let [paymentMethods, setpaymentMethods] = useState([]);
  const formik = useFormik({
    isInitialValid:false,
    validateOnMount:false,
    initialValues:{
      id_paymentmethod:1,
      amount_money:0

    },
    validationSchema: yup.object().shape({
      id_paymentmethod:yup
      .string('Enter payment method')
      .required("Please select payment method"),
      amount_money: yup
      .number("Enter withdrawal requested amount")
      .max(props.wallet.balance,`Withdrawal amount cannot be greator than ${props.wallet.balance}`)
      .min(0,"Value must be greator than or equal to 0")
      .required('Value is required. Only numeric value accepted'),
      user_receiving_number:yup
      .string('Enter receiving phone number')
      .matches(
        /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
        "Receiving number is invalid"
      )
      .required('Receiving number is required. Only numeric value accepted'),
    }),

    onSubmit: (values, actions) => {
      //  console.log(customParams);
        onFormSubmit(values,actions)
    }
  });
  const onFormSubmit = (data, customParams, form) => {
    var formdata=new FormData();    
    var keys=Object.keys(data);
    keys.forEach((v,i)=>{
      formdata.append(v,data[v]);
    })
    customParams.onSave(formdata,form);

    // async request which may result error
    try {
      // await fetch()
    } catch (e) {
      // handle your error
    }
  }
  useEffect(() => {
    axios.get(`${API_URL}/paymentmethods`, {
      headers: {
        Authorization: `JWT ${token}`, 'Content-Type': 'application/json'
      },
    })
      .then(async (res) => {
        setpaymentMethods(res.data.data);
      });
  }, [token,props.obj,props.wallet,formik.values]);

  return (
    <div className="DataPAGE__form">
      <form onSubmit={form => { onFormSubmit(formik.values,props, form); }}>
         
        <WalletCard balance={props.wallet.balance} />
        <br/>
        <TextField disabled={props.isView} 
          fullWidth 
          type="number"
          label="Request amount"
          id="amount_money"
          name="amount_money"
          required
          inputProps={{ min: 0 }}
          value={formik.values.amount_money}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.amount_money && Boolean(formik.errors.amount_money)}
          helperText={formik.touched.amount_money && formik.errors.amount_money}
        />  
     
        <TextField disabled={props.isView} 
          fullWidth 
          type="number"
          label="Receiving Number"
          id="user_receiving_number"
          name="user_receiving_number"
          required
          inputProps={{ min: 0 }}
          value={formik.values.user_receiving_number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.user_receiving_number && Boolean(formik.errors.user_receiving_number)}
          helperText={formik.touched.user_receiving_number && formik.errors.user_receiving_number}
        />  
         <br />  <br />
        <FormControl fullWidth>
          <InputLabel id="id_paymentmethod">Payment Method</InputLabel>
          <Select
            labelId="id_paymentmethod"
            id="id_paymentmethod"
            name='id_paymentmethod'
            label="Payment Method"
            disabled={props.isView}
            value={formik.values.id_paymentmethod}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.id_paymentmethod && Boolean(formik.errors.id_paymentmethod)}
          >
            {
              paymentMethods.map((v, i) => {
                console.log(v);
                return <MenuItem key={i} value={v.id}>{v.name}</MenuItem>
              })
            }
          </Select>
        </FormControl>
        <br />
        <Typography align='right'>
        <Button  type="submit" disabled={!formik.isValid || props.isView || (props.balance<=0)}><SaveOutlined /> Request</Button>
        </Typography>
      </form>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    user: state.user.user,
    wallet: state.user.wallet,
    transactions: state.user.transactions,
    permissions:state.user.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddNewUserTransaction);