import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { TextField,Switch,  Typography } from '@material-ui/core';
import * as yup from 'yup'; // for everything
import { useState } from 'react';
import Button from '../../../components/Button/Button';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import { handleSwitchChange } from '../../../utils/helpers';
const AddNewPaymentMethod = (props) => {
  const [initialValues]=useState(props.obj);
  const formik = useFormik({
  isInitialValid:props.obj.id===0 ? false:true,
  initialValues:initialValues,
  validationSchema:yup.object({
    name: yup
      .string('Enter your name')
      .required('Name is required'),
   associatednumber: yup
      .number('Enter associated number')
      .required('Associated number is required'),
   status: yup
      .string('Enter status')
      .required('Status is required'),
  }),
  
  onSubmit:(values, actions ) => {
  //  console.log(customParams);
  //  onFormSubmit(values,actions)
  }});
  const onFormSubmit =(data,customParams,form) => {
    customParams.onSavePaymentMethod(data,form);
    // async request which may result error
    try {
      // await fetch()
    } catch (e) {
      // handle your error
    }
  }
  useEffect(()=>{
    
  },[])
  return (
    <div className="DataPAGE__form">
          <form onSubmit={form => { onFormSubmit(formik.values,props, form); }}>
            <TextField disabled={props.isView} type='hidden' id="id" name="id" value={formik.values.id} />
            <TextField disabled={props.isView} 
          fullWidth
          id="name"
          name="name"
          required
          label="Name"
          inputProps={{ min: 0 }} 
          type="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
                <TextField disabled={props.isView} 
          fullWidth 
          label="Associated Number"
          id="associatednumber"
          name="associatednumber"
          required
          value={formik.values.associatednumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.associatednumber && Boolean(formik.errors.associatednumber)}
          helperText={formik.touched.associatednumber && formik.errors.associatednumber}
        />
       
        <FormControlLabel  label="status" control={<Switch value={formik.values.status} disabled={props.isView}  onChange={(e)=>handleSwitchChange(e,formik,'Enable','Disable')}  defaultChecked={formik.values.status==='Enable'? true:false} inputProps={{ 'aria-label': 'Status' }} />}  />
        
        <br/>
        <Typography align='right'>
            <Button  type="submit" disabled={!formik.isValid || props.isView }><SaveOutlined /> Save Payment Method</Button>
        </Typography>
          </form>
    </div>
  );
};

export default AddNewPaymentMethod;
