import * as actionTypes from './actionTypes';
import axios from 'axios';
import { API_URL } from '../../utils/api';
export const startDeleting = (msg,deleteId,deleteUrl,onDeleteSuccess) =>{
  return{
    type:actionTypes.START_DELETING,
    msg:msg,
    deleteUrl:deleteUrl,
    deleteId:deleteId,
    onDeleteSuccess:onDeleteSuccess
  }
};

export const stopDeleting = () =>{
  return{
    type:actionTypes.STOP_DELETING
  }

};
export const responseMsg=(msg)=>{
  return{
    type:actionTypes.START_DELETE_RESPONSE,
    msg:msg,
  }
};


export const confirmDelete =(token,id,url,onDeleteSuccess) => {
    return dispatch  =>{
 
     return  axios.post(`${API_URL+url}`,{id:id}, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
        .then(async(res) => {       
          dispatch(responseMsg(res.data.message));
          await onDeleteSuccess();
          setTimeout(function(){
            dispatch(stopDeleting());           
          },3000)
         
        })
        .catch(err=>{
            dispatch(stopDeleting());
        });
    }  
};