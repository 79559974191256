import React,{useState} from 'react';
import { connect } from 'react-redux';
import './ChangePasswordPage.scss';
import * as yup from 'yup';
import axios from 'axios';
import { API_URL } from '../../utils/api';
import * as actions from '../../store/actions';
import { toastr } from 'react-redux-toastr';
import { useFormik } from 'formik';
import { TextField } from '@material-ui/core';
import { Button } from 'react-materialize';
import { useHistory } from "react-router";
import AvatarIcon from '@material-ui/icons/PermIdentity';
import SyncLockOutlinedIcon from '@mui/icons-material/SyncLockOutlined';
const ChangePasswordPage=(props)=>{
  const history = useHistory();
  let token = localStorage.getItem('token');
  let uId = localStorage.getItem('userid');
  let [showForm,setshowForm]=useState(false);
   const updateUser = (values, actions) => {
    props.onStartLoading();
    const data = {
      password: values.password
    };

    axios.put(`${API_URL}/changepassword`, data, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then(res => {
   //     console.log(res.data);
        props.onStopLoading();
        if(res.data.success){
        toastr.success("Success",res.data.message);
        window.setTimeout(()=>{
          history.push("/logout");
        }, 2000);
      }else{
        toastr.error("Error",res.data.message);
      }
      }).catch(err => {
      console.log(err.response.data);
      props.onStopLoading();
    });
  }; 
  const formik = useFormik({
    isInitialValid:false,
    validateOnMount:false,
    initialValues:{
      password: '',
      confirmpassword: '',
    },
    validationSchema: yup.object().shape({
      password: yup.string().required('Required').min(8, 'Password should be of minimum 8 characters length'),
      confirmpassword: yup.string().required('Required').oneOf([yup.ref('password')], 'Passwords must match')
    }),
    
    onSubmit:(values, actions ) => {
      actions.preventDefault();
      updateUser(values, actions);

    //  console.log(customParams);
    //  onFormSubmit(values,actions)
    }});
    const onFormSubmit = (data, form) => {
      form.preventDefault();
      // var formdata=new FormData();    
      // var keys=Object.keys(data);
      // keys.forEach((v,i)=>{
      //   formdata.append(v,data[v]);
      // })
      updateUser(data,form)
  
      // async request which may result error
      try {
        // await fetch()
      } catch (e) {
        // handle your error
      }
    }
  const {name, surname, login, email } = props.user;
   



    console.log('SETTINGS', props.user);
    return (
      <div className="SettingsPage">
        <div className="SettingsPage__user">
          <div className="SettingsPage__user-avatar">
            <AvatarIcon className="icon"/>
          </div>
          <div className="SettingsPage__user-data">
            <div>Login:<span>{login}</span></div>
            <br/>
            <div><span>{name} {surname}</span></div>
            <div>{email}</div>

            <Button onClick={()=>setshowForm(!showForm)}><SyncLockOutlinedIcon/></Button>
          </div>
        </div>
        {
        showForm &&
        <div className="SettingsPage__edit-form">
        
              <form onSubmit={form => { onFormSubmit(formik.values, form)}} className="">
                  <TextField type="password" placeholder='Password'  name="password"   value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}/>
           
                  <TextField type="password" placeholder='Confirm Password'  name="confirmpassword" value={formik.values.confirmpassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.confirmpassword && Boolean(formik.errors.confirmpassword)}
          helperText={formik.touched.confirmpassword && formik.errors.confirmpassword}/>
                <Button disabled={!formik.isValid} type="submit">Update</Button>
              </form> 
        </div>
        }
      </div>

    );
  
}
const mapStateToProps = state => {
  return {
    user: state.user.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onStartLoading:()=>dispatch(actions.startLoading()),
    onStopLoading:()=>dispatch(actions.stopLoading()),
  //  updateUserData:(token,uId)=>dispatch(actions.getUserInfo(token,uId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);
