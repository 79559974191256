import React from 'react';
import { Link } from 'react-router-dom';

const registeredUser = props => {

  //TODO: better styling
  return (
    <div className="new-user-info">
     <h5 className='center'>Thankyou for the registration.  <Link to="/login" className='center'>Go to Sign In</Link> </h5>
       <div>
        <div>{props.user.username}</div>
        <div>{props.user.email}</div>
        <div>{props.user.name}</div>
      </div>
      <Link to="/login" className='center'>Go to Sign In</Link>
    </div>
  );
};

export default registeredUser;
