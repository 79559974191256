import React, { useEffect } from 'react';
import './App.scss';
import LoginPage from '../LoginPage/LoginPage';
import * as actions from '../../store/actions';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import HomePage from '../HomePage/HomePage';
import Loading from '../../components/Loading/Loading';
import ReduxToastr from 'react-redux-toastr';
import RegisterPage from '../RegisterPage/RegisterPage';
import Nav from '../../components/Nav/Nav';
import TransactionsPage from '../TransactionsPage/TransactionsPage';
import NotFound from '../NotFoundPage/NotFound';
import LogoutPage from '../LogoutPage/LogoutPage';
import PrivateRoute from '../../utils/privateRoute';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import HamburgerIcon from '@material-ui/icons/Reorder';
import SettingsPage from '../SettingsPage/SettingsPage';
import { useState } from 'react';
import UsersPage from '../UsersPage/UsersPage';
import DeleteDialog from '../../components/Dialog/DeleteDialog';
import PackagesPage from '../PackagesPage/PackagesPage';
import UserPackagesPage from '../UserPackagesPage/UserPackagesPage';
import PaymentMethodsPage from '../PaymentMethodsPage/PaymentMethodsPage';
import UserTransactionsPage from '../UserTransactionsPage/UserTransactionsPage';
import TasksPage from '../TasksPage/TasksPage';
import UserTasksPage from '../UserTasksPage/UserTasksPage';
import WalletPage from '../WalletsPage/WalletsPage';
import UserComplaintsPage from '../UserComplaintsPage/UserComplaintsPage';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LandingPage from '../LandingPage/LandingPage';
import ChangePasswordPage from '../ChangePassword/ChangePasswordPage';
import ComplaintsPage from '../ComplaintsPage/ComplaintsPage';
 function App(props) {
  const [showMobileNav, setshowMobileNav] = useState(false);
  const openMenu = () => {
    setshowMobileNav(true);
  };
  const closeMobileMenu = () => {
    setshowMobileNav(false);
  };
  let routes = (
    <Switch>
      <Route path={["/login/:type?/:key?"]} component={LoginPage}/>
      <Route path={["/LandingPage"]} component={LandingPage}/>
      {/* <Route path="/register" component={RegisterPage}/> */}
      <Route path={["/validateuser/:validationcode","/register/:reference_key?"]} component={RegisterPage}/>
      <PrivateRoute path="/" exact component={HomePage}/>
      <PrivateRoute path="/users" exact component={UsersPage}/>
      <PrivateRoute path="/packages" exact component={PackagesPage}/>
      <PrivateRoute path="/paymentmethods" exact component={PaymentMethodsPage}/>
      <PrivateRoute path="/transactions" exact component={TransactionsPage}/>
      <PrivateRoute path="/usertasks" exact component={UserTasksPage}/>
      <PrivateRoute path="/userpackages" exact component={UserPackagesPage}/>
      <PrivateRoute path="/usertransactions" exact component={UserTransactionsPage}/>
      <PrivateRoute path="/tasks" exact component={TasksPage}/>
      <PrivateRoute path="/wallets" exact component={WalletPage}/>
      <PrivateRoute path="/usercomplaints" exact component={UserComplaintsPage}/>
      <PrivateRoute path="/complaints" exact component={ComplaintsPage}/>
      <PrivateRoute path="/settings" exact component={SettingsPage}/>
      <Route path="/logout" exact component={LogoutPage}/>
      <Route path="/changepassword" exact component={ChangePasswordPage}/>
      <Route path="*" exact component={NotFound}/>
    </Switch>
  );

  let layout = null;

  if (!props.isAuth) {
    layout = (
      <div className="local-boostrap App">
        {routes}
      </div>
    );
  } else if (props.isAuth && props.isUserInfo && props.isTransactions) {
    layout = (
      <div className="App">
        <div className="App__dashboard">
          <Nav permissions={props.permissions} close={closeMobileMenu} show={showMobileNav}/>
          {props.deleting && <DeleteDialog />}
          <div className="App__dashboard__container" onClick={()=>showMobileNav && closeMobileMenu()}>
            <div className="App__dashboard__container__top-bar">
              <HamburgerIcon className="mobile-menu-icon" onClick={openMenu}/>
              <div className="App__dashboard__container__top-bar__email">
                {props.user.email}
              </div>
              <div class="App__dashboard__container__top-bar_logout">
       <NavLink className="App__dashboard__container__top-bar_logout_link"  exact activeClassName="activeLink" to="/logout"><LogoutOutlinedIcon/></NavLink>
       </div>
            </div>
            {routes}
          </div>
        </div>
      </div>
    );
  } else {
    layout = (
      <LoadingScreen/>
    );
  }

  useEffect(() => {
    props.tryAutoLogIn();
    },[]);
    const loading = props.isLoading || props.isLoadingUser || props.loading ? <Loading/> : null;
  return (
    <>
    {loading}
    <ReduxToastr
      timeOut={3000}
      newestOnTop={false}
      preventDuplicates
      position="top-right"
      transitionIn="fadeIn"
      transitionOut="fadeOut"

      closeOnToastrClick/>
    {layout}
  </>
  )
}
const mapStateToProps = state => {
  return {
    isAuth: state.auth.token,
    isLoading: state.auth.loading,
    isLoadingUser: state.user.loading,
    isTransactions: state.user.transactionsLoaded,
    // isLoadingTransactions:state.transaction.loading,
    isUserInfo: state.user.user.id !== null,
    loading: state.loading.loading,
    user:state.user.user,
    permissions:state.user.permissions,
    deleting:state.delete.deleting

  };
};

const mapDispatchToProps = dispatch => {
  return {
    tryAutoLogIn: () => dispatch(actions.authCheckState()),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
