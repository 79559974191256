import React, {  useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './HomePage.scss';
import ReactApexCharts from 'react-apexcharts';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import * as actions from '../../store/actions';
import { Button } from 'react-materialize';
import { getToaster } from '../../utils/helpers';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const HomePage=(props)=>{
  let {wallet,transactions} = props;
  let [superadminKpis,setsuperadminKpis]=useState({
    approved_package_amount:0,
    pending_package_amount:0,
    rejected_package_amount:0,
    approved_withdrawal_amount:0,
    pending_withdrawal_amount:0,
    reject_withdrawal_amount:0,
    reward_amount:0,
    deduction_amount:0
  });
  let token = localStorage.getItem('token'); 
  console.log(transactions.length);
  const [options,]=useState({
    chart: {
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      colors:['#035ee6']
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 700
            }
          }
        }
      },
    },
    xaxis: {
      categories: ['No of Package Subscriptions','No of WithDrawals','No of Deposits','No of Deductions'],
    },
    colors:['#004080','#00e396','#3d464d'],
    responsive: [{
      breakpoint: 480,
      options: {
       
        xaxis: {
          categories: ['Subscriptions','WithDrawals','Deposits','Deductions'],
        },
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
  });
  const [series,setseries]=useState([{
    name: '',
    data: [props.transactions.filter(x=>x.transfer_title==='Package_Purchase').length,props.transactions.filter(x=>x.transfer_title==='Wallet_Withdraw').length,props.transactions.filter(x=>x.transfer_title==='Wallet_Deposit').length,props.transactions.filter(x=>x.transfer_title==='Wallet_Deduction').length],
  }]);
  const copyReferencekeytoClipboard=()=>{
    navigator.clipboard.writeText(props.user.reference_key);
    getToaster('Success','Reference Key', "Copied to clipboard");
  }
  useEffect(()=>{
    console.log(props.transactions);
    
    setsuperadminKpis({
      approved_package_amount: props.transactions.filter(x=>(x.transfer_title==='Package_Purchase' && x.authorization_status==='Approved')|| (x.transfer_title==='Package_Difference' && x.authorization_status==='Pending')).reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount_money
      },0),
      pending_package_amount:props.transactions.filter(x=>(x.transfer_title==='Package_Purchase' || x.transfer_title==='Package_Difference')&& x.authorization_status==='Pending').reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.transfer_title==='Package_Purchase' ? currentValue.amount_money:currentValue.remaining_amount_money)
      },0),
      rejected_package_amount:props.transactions.filter(x=>((x.transfer_title==='Package_Purchase' || x.transfer_title==='Package_Difference') && x.authorization_status==='Rejected')).reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.transfer_title==='Package_Purchase' ? currentValue.amount_money:currentValue.remaining_amount_money)
      },0),
      approved_withdrawal_amount:props.transactions.filter(x=>(x.transfer_title==='Wallet_Withdraw' && x.authorization_status==='Approved')).reduce((accumulator, currentValue) => {
        return accumulator +  currentValue.amount_money
      },0),
      pending_withdrawal_amount:props.transactions.filter(x=>(x.transfer_title==='Wallet_Withdraw' && x.authorization_status==='Pending')).reduce((accumulator, currentValue) => {
        return accumulator +  currentValue.amount_money
      },0),
      reject_withdrawal_amount:props.transactions.filter(x=>(x.transfer_title==='Wallet_Withdraw' && x.authorization_status==='Rejected')).reduce((accumulator, currentValue) => {
        return accumulator +  currentValue.amount_money
      },0),
      reward_amount:props.transactions.filter(x=>(x.transfer_title==='Wallet_Deposit' && x.authorization_status==='Approved')).reduce((accumulator, currentValue) => {
        return accumulator +  currentValue.amount_money
      },0),
      deduction_amount:props.transactions.filter(x=>(x.transfer_title==='Wallet_Deduction' && x.authorization_status==='Approved')).reduce((accumulator, currentValue) => {
        return accumulator +  currentValue.amount_money
      },0)
    });

  
    setseries(
      [{
        name: 'Approved',
        data: [props.transactions.filter(x=>x.transfer_title==='Package_Purchase' && x.authorization_status==='Approved').length,props.transactions.filter(x=>(x.transfer_title==='Wallet_Withdraw' && x.authorization_status==='Approved')).length,props.transactions.filter(x=>(x.transfer_title==='Wallet_Deposit' && x.authorization_status==='Approved')).length,props.transactions.filter(x=>(x.transfer_title==='Wallet_Deduction' && x.authorization_status==='Approved')).length],
      },{
        name:"Pending",
        data:[props.transactions.filter(x=>(x.transfer_title==='Package_Purchase' || x.transfer_title==='Package_Difference')&& x.authorization_status==='Pending').length,props.transactions.filter(x=>(x.transfer_title==='Wallet_Withdraw' && x.authorization_status==='Pending')).length,0,0]
      },{
        name:"Rejected",
        data:[props.transactions.filter(x=>((x.transfer_title==='Package_Purchase' || x.transfer_title==='Package_Difference') && x.authorization_status==='Rejected')).length,props.transactions.filter(x=>(x.transfer_title==='Wallet_Withdraw' && x.authorization_status==='Rejected')).length,0,0]
      }]);
      return ()=>{
        props.getUserInfo(token,props.user.id);
      }
  },[props.transactions,props.wallet]);

  return (
    <>
    {props.user.id ?
      <div className="App__dashboard__container__Home">
        {props.role.title==='SuperAdmin'  ?  <Grid container spacing={2}>
        <Grid item xs={12} sm={4} >
        <div className="card kpi_home fix_superkpi_height">
                <div className="kpi_home_body">
                    <span className="kpi_home_body_icon"><ViewInArOutlinedIcon/></span>
                    <div className="kpi_home_body_info">
                        <h3>PKR {superadminKpis.approved_package_amount}</h3>
                        <span>Total Approved Package Subscription Amount</span>
                        <h3>PKR {superadminKpis.pending_package_amount}</h3>
                        <span>Total Pending Package Subscription Amount</span>
                        <h3>PKR {superadminKpis.rejected_package_amount}</h3>
                        <span>Total Rejected Package Subscription Amount</span>
                    </div>
                </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} >
          <div className="card kpi_home fix_superkpi_height">
                <div className="kpi_home_body">
                    <span className="kpi_home_body_icon"><ViewInArOutlinedIcon/></span>
                    <div className="kpi_home_body_info">
                        <h3>PKR {superadminKpis.approved_withdrawal_amount}</h3>
                        <span>Total Approved Withdrawal Amount</span>
                        <h3>PKR {superadminKpis.pending_withdrawal_amount}</h3>
                        <span>Total Pending Withdrawal Amount</span>
                        <h3>PKR {superadminKpis.reject_withdrawal_amount}</h3>
                        <span>Total Rejected Withdrawal Amount</span>
                    </div>
                </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} >
            <div className="card kpi_home fix_superkpi_height">
                <div className="kpi_home_body">
                    <span className="kpi_home_body_icon"><ViewInArOutlinedIcon/></span>
                    <div className="kpi_home_body_info">
                        <h3>PKR {superadminKpis.reward_amount}</h3>
                        <span>Total Approved Reward Amount</span>
                        <h3>PKR {superadminKpis.deduction_amount}</h3>
                        <span>Total Approved deduction Amount</span>
                    </div>
                </div>
            </div>
          </Grid>
        </Grid>:
        <Grid container spacing={2}>
        <Grid item xs={12} sm={3} >
        <div className="card kpi_home">
                <div className="kpi_home_body">
                    <span className="kpi_home_body_icon"><ViewInArOutlinedIcon/></span>
                    <div className="kpi_home_body_info">
                        <h3>PKR {wallet.balance}</h3>
                        <span>Balance</span>
                    </div>
                </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} >
          <div className="card kpi_home">
                <div className="kpi_home_body">
                    <span className="kpi_home_body_icon"><ViewInArOutlinedIcon/></span>
                    <div className="kpi_home_body_info">
                        <h3>PKR {wallet.deposited_balance}</h3>
                        <span>Deposit</span>
                    </div>
                </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} >
            <div className="card kpi_home">
                <div className="kpi_home_body">
                    <span className="kpi_home_body_icon"><ViewInArOutlinedIcon/></span>
                    <div className="kpi_home_body_info">
                        <h3>PKR {wallet.withdrawn_balance}</h3>
                        <span>Withdraw</span>
                    </div>
                </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} >
            <div className="card kpi_home">
                <div className="kpi_home_body">
                    <span className="kpi_home_body_icon"><ViewInArOutlinedIcon/></span>
                    <div className="kpi_home_body_info">
                        <h3>PKR {wallet.deduction}</h3>
                        <span>Deduction</span>
                    </div>
                </div>
            </div>
          </Grid>
        </Grid>}

       
     

        {/* <div className="HomeDashboard__account-balance"> */}
          {/* <div className="HomeDashboard__account-balance__bill">
            <p className="title">Bill number</p>
            <p className="bill">{props.bill.account_bill}</p>
            <p className="email">{props.user.name} {props.user.surname}</p>
          </div> */}
          {/* <div className="HomeDashboard__account-balance__funds">
            <p className="title">Balance</p>
            <p className="balance">PKR {billBalance}</p>
          </div> */}
          {/*  <div className="HomePage__container__account-balance__budget">
        budget
      </div>*/}
        {/* </div> */}
        <div className="App__dashboard__container__Home__overview">
          <div className="App__dashboard__container__Home__overview__chart">
            {series[0].data ?
              <ReactApexCharts   options={options} height={'100%'} series={series} type="bar" 
                               className="charts"/> : null}

          </div>
        </div>
        <Grid container>
        <Grid style={{ display:"flex",marginTop:'1%',justifyContent: 'flex-end'}} item xs={12} sm={12}><Button onClick={()=>copyReferencekeytoClipboard()}>Copy Reference Key</Button></Grid>
        </Grid>
      </div> : null
    }
  </>
  )
}
const mapStateToProps = state => {
  return {
    user: state.user.user,
    wallet: state.user.wallet,
    transactions: state.user.transactions,
    permissions:state.user.permissions,
    role:state.user.role
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getUserInfo:(token,uId)=>dispatch(actions.getUserInfo(token,uId))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
