import React from 'react'
import { connect } from 'react-redux';
import CustomDataGrid from '../../../components/DataGrid/CustomDataGrid';
import * as actions from '../../../store/actions';
import { useEffect } from 'react';

const PaymentMethodList=(props)=>{

  const columns = [
    {
      field: 'id', headerName: 'ID', width: 70
    },
    {
      field: 'name', headerName: 'Name', width: 200
    },
    {
      field: 'associatednumber', headerName: 'Associated Number', width: 200
    },
    {
      field: 'status', headerName: 'Status', width: 200
    }
    ,
    {field:'Action'}
  ]
  useEffect(()=>{
   // props.homePageStart();
  },[]);
  return (
    <>
      <CustomDataGrid  url="paymentmethods" deleteUrl="/paymentmethods/delete" onEdit={props.onEdit} serverListenerUrl="/paymentmethods"  columns={columns} />
</>
  )
}
const mapStateToProps = state => {
  return {
    user: state.user.user,
    bill: state.user.bill,
    transactions: state.user.transactions,
    permissions:state.user.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGettingTransaction: (token, uId) => dispatch(actions.gettingTransactions(token, uId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodList);
