import React from 'react';
import { connect } from 'react-redux';
import './SettingsPage.scss';
import AvatarIcon from '@material-ui/icons/PermIdentity';
import * as yup from 'yup';
import axios from 'axios';
import { API_URL } from '../../utils/api';
import * as actions from '../../store/actions';
import { toastr } from 'react-redux-toastr';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Button } from 'react-materialize';
const SettingsPage=(props)=>{
  let token = localStorage.getItem('token');
  let uId = localStorage.getItem('userid');
  let [userInfo,setUserInfo]=useState({
    name: props.user.name,
    surname: props.user.surname,
    email: props.user.email,
    showForm: false,
  });



  const showEditForm = () => {
    setUserInfo({
      ...userInfo,
      showForm: true,
    });
  };


   const updateUser = (values, actions) => {
    props.onStartLoading();
    const data = {
      name: values.name,
      email: values.email,
    };

    axios.put(`${API_URL}/user`, data, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then(res => {
        console.log(res.data);
        props.onStopLoading();
        props.updateUserData(token,uId);
        toastr.success("Success","User updated successfully")
      }).catch(err => {
      console.log(err.response.data);
      props.onStopLoading();
    });
  }; 
  const formik = useFormik({
    isInitialValid:true,
    validateOnMount:true,
    initialValues:{
      email: props.user.email,
      name: props.user.name,
      surname: props.user.surname,
    },
    validationSchema: yup.object().shape({
      email: yup.string()
        .email()
        .typeError('Must be a email').required('Required'),
      name: yup.string().required('Required'),
      surname: yup.string().required('Required'),
  
    }),
    
    onSubmit:(values, actions ) => {
      actions.preventDefault();
      updateUser(values, actions);
    //  console.log(customParams);
    //  onFormSubmit(values,actions)
    }});
    const onFormSubmit = (data, form) => {
      form.preventDefault();
      // var formdata=new FormData();    
      // var keys=Object.keys(data);
      // keys.forEach((v,i)=>{
      //   formdata.append(v,data[v]);
      // })
      updateUser(data,form)
  
      // async request which may result error
      try {
        // await fetch()
      } catch (e) {
        // handle your error
      }
    }
  const { name, surname, login, email } = props.user;
   



    console.log('SETTINGS', props.user);
    return (
      <div className="SettingsPage">
        <div className="SettingsPage__user">
          <div className="SettingsPage__user-avatar">
            <AvatarIcon className="icon"/>
          </div>
          <div className="SettingsPage__user-data">
            <div>Login:<span>{login}</span></div>
            <br/>
            <div><span>{name} {surname}</span></div>
            <div>{email}</div>

            <Button onClick={()=>showEditForm()}>Edit data</Button>
          </div>
        </div>
        {
        userInfo.showForm &&
        <div className="SettingsPage__edit-form">
        
              <form onSubmit={form => { onFormSubmit(formik.values, form)}} className="">
                <label>
                  <input type="text"  name="name"   value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}/>
                </label>
           
                <label>
                  <input type="text"  name="email" value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}/>
                </label>
                <Button type="submit">Update</Button>
              </form>
        </div>}
      </div>

    );
  
}
const mapStateToProps = state => {
  return {
    user: state.user.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onStartLoading:()=>dispatch(actions.startLoading()),
    onStopLoading:()=>dispatch(actions.stopLoading()),
    updateUserData:(token,uId)=>dispatch(actions.getUserInfo(token,uId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
