import React from 'react';
import './PreviewFile.module.scss';
const PreviewFile = ({ file, width, height }) => {

  let fileData=file;
  // `await` can only be used in an async body, but showing it here for simplicity.
  
  const [preview, setPreview] = React.useState(null);

  const reader = new FileReader();
  if(typeof(file)==="string"){
     fetch(file).then(r => { 
      let blob= r.blob().then((value)=>{;
     // console.log(blob);
      debugger
      fileData=value;
      reader.readAsDataURL(value); 
    })
    });
   // const fileToread = await getFileFromUrl(file, file);
    
  }else{

  reader.readAsDataURL(fileData);
  }
  function isFileImage(fileData) {
    console.log(fileData);
    return fileData && fileData['type'].split('/')[0] === 'image';
  }

  reader.onload = () => {

    setPreview(isFileImage(fileData) ? reader.result : "/default.svg");

  };
 
  return (

    <div className='preview-container'>
      <img src={preview} className='preview' alt="Preview" width={width} height={height} />
      <label>{fileData.name}</label>
    </div>

  )

}

export default PreviewFile