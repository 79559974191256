import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, Typography } from '@mui/material';
import { API_URL } from '../../utils/api';
import LocalDataGrid from '../DataGrid/LocalDataGrid'
import TransactionDetailCard from '../Card/TransactionDetailCard';
import { PlagiarismSharp } from '@mui/icons-material';
import { useState,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseRounded from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
const TransactionDetailList=({transactiondetails})=>{
  const [dialog,setDialog]=useState(false);
  const [data,setData]=useState({imgUrl:"",amount_money:0,created_date_time:""});
  
  const handleShowDialog = (imgUrl,amount_money,created_date_time) => {
      setDialog(true);
      setData({imgUrl:`${API_URL}/paymentreceipts/${imgUrl}`,amount_money:amount_money,created_date_time:created_date_time})
      console.log("cliked");
    };
  const handleHideDialog = () => {
      setDialog(false);
      setData({})
      console.log("closed");
    };
    useEffect(()=>{

    },[dialog])
  let columns=[{
    field:'user_evidence_file',
    headerName:"Posted Receipts",
    width:700,
    renderCell: (params) => <TransactionDetailCard handleShowDialog={handleShowDialog} id={params.row.id} user_evidence_file={params.row.user_evidence_file} amount_money={params.row.amount_money} created_date_time={params.row.created_date_time} /> 
  }]
  return (
     <div style={{ height: 400, width: '100%' }}>
       <Dialog
                    className="dialog"
                    open={dialog}
                    onClose={handleHideDialog}
                  //  onClick={handleHideDialog}
                  >
                        <IconButton onClick={handleHideDialog} sx={{ ml: 'auto' }}>
      <CloseRounded /></IconButton>
 <DialogTitle
    sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection:'column',
      textAlign:'center',
      padding: '0px 24px 10px'
    }}
  >
    <Typography  variant="h6" gutterBottom>Amount:{data.amount_money}</Typography> 
    <Typography  variant="h6" color={'text.secondary'} gutterBottom>Posted Date:{data.created_date_time}</Typography>
                  
      </DialogTitle>
                    <img
                      className="image"
                      style={{width: '80%',
                        height: '80%',
                        margin: '0 auto',
                        marginBottom: '15px'}}
                      src={data.imgUrl}
                  //    onClick={handleShowDialog}
                      alt="no image"
                    />
         
    
              </Dialog>
    <LocalDataGrid
      rowHeight={200}
      rows={transactiondetails}
      columns={columns}
    />
  </div>)
}

export default TransactionDetailList;