import { toastr } from 'react-redux-toastr';
export const MAX_FILE_SIZE = 10485760;
export const Wallet_Withdraw="Wallet_Withdraw";
export const Wallet_Deduction="Wallet_Deduction";
export const Package_Purchase="Package_Purchase";
export const Package_Difference="Package_Difference";
export const Wallet_Deposit="Wallet_Deposit";
export const getPagePermission = (permissions,accessurl) =>{
    return permissions.filter(x=>x.menu.accessurl===accessurl)[0];
}
export const getToaster=(type,title,msg)=>{
    if(type==="Success"){
        toastr.success(title, msg)
    }else{
        toastr.error(title, msg)
    }
}

export const validFileExtensions = {package_image_file:['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'],  user_evidence_file: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'],approver_evidence_file:['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };

export const getAllowedExt=(type)=>{
  return validFileExtensions[type].map((e) => `.${e}`).toString()
} 

export const isValidFileType=(fileName, fileType)=>{
    return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
}
export const handleSwitchChange = (event,formik,trueValue,falseValue) => {
    if(event.target.checked){
      formik.values.status=trueValue;
    }else{
      formik.values.status=falseValue;
    }
  };
  export const renderTransfer_Tile_Switch=(param)=>{
    switch(param) {
      case Package_Purchase:
        return 'Subscription';
      case Wallet_Deposit:
        return 'Deposit';
      case Wallet_Withdraw:
        return 'Withdrawal'
     case Wallet_Deduction:
          return 'Deduction'
     case Package_Difference:
          return 'Difference'
      default:
        return 'foo';
    }
  }
  export const getLocalStorageValue = (s) => localStorage.getItem(s);

  // export const renderErrorFromArray=({field, errorsArray, index})=>{
  //   if (error[index] !== undefined) {
  //     return (
  //       <Text
  //         ref={ref => {
  //           const errorRef = 'errorRef_' + field + index;
  //           this[errorRef] = ref;
  //         }}
  //         style={Styles.error}>
  //         {error[index]}
  //       </Text>
  //     );
  //   } else {
  //     return <View />;
  //   }
  // }