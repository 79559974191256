import React, {  useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import UserPackageList from './UserPackageList/UserPackagesList';
import AddNewUserPackage from './AddUserPackage/AddNewUserPackage';
import { getToaster } from '../../utils/helpers';
import { Button } from 'react-materialize';
import { API_URL } from '../../utils/api';
import axios from 'axios';
import { getPagePermission } from '../../utils/helpers';
const UserPackagesPage=(props)=>{
  let token = localStorage.getItem('token'); 
  const [isView,]=useState(false);
  const [activePage,setactivePage]=useState('USERPACKAGE_LIST');
  const [transactionid,setTransactionID]=useState(0);
  const [packageRecord,setpackageRecord]=useState({
    id: 0,
    name:'',
    price:0,
    expirydays:0,
    reward:0,
    referencereward:0,
    status:'Disable'
  });
  const [isAddDisable,setisAddDisable]=useState(true);
  const [obj,setObj]=useState({
    id:transactionid,
    id_package: packageRecord.id,
    id_paymentmethod:"",
    user_evidence_file: undefined,
  });
  const BuyPackage= async (values,e)=>{
    e.preventDefault();
    console.log(values);
   props.onLoadingStart();

    return  axios.post(`${API_URL}/userpackages/add`,values, {
      headers: {
        Authorization: `JWT ${token}`,'Content-Type':'application/json'
      },
    })
      .then(async(res) => {     
        let data=res.data;  
         props.onLoadingStop();
         if (data.success) {
           getToaster('Success','User package payment status', data.message);
           setactivePage('USERPACKAGE_LIST');
         }else{
           getToaster('Error',"User package saving error", data.message);
         }
       
      });
  }

 
  const getPaymentPage=(packageObj)=>{
    setpackageRecord(packageObj);
    setactivePage("BUY_NEW_PACKAGE");
  }
  
 const onDifferencePay=(packageObj,transaction)=>{
  console.log(packageObj);
  console.log(transaction);
  setTransactionID(transaction);
  setpackageRecord(packageObj);
  setactivePage("BUY_NEW_PACKAGE");
 } 


  

  useEffect(()=>{
    if (token == null) {
       props.history.replace('/login');
    }
 
    const changePage = (page) => {
      setactivePage(page);
      if(activePage==="USERPACKAGE_LIST"){
        setisAddDisable(true);
      }
      else{
        console.log(transactionid);
        setObj({
          id:transactionid,
          id_package: packageRecord.id,
          id_paymentmethod:"",
          user_evidence_file: undefined,
        });
        setisAddDisable(false);
      }
    };
    let permissionData=getPagePermission(props.permissions,"userpackages");
    console.log(permissionData); 
      changePage(activePage);
  },[activePage,props.history,props.permissions,token,packageRecord,transactionid]);


  
    return (
      <>
        {props.user.id ?
     <div className="DataPAGE">
     <div className="DataPAGE__add">
              <nav>
                <ul>
                <li><Button disabled={isAddDisable}>Payment Section</Button></li>
                  <li><Button onClick={() => setactivePage('USERPACKAGE_LIST')}>Package list</Button></li>
                </ul>
              </nav>
            </div>
            {activePage === 'USERPACKAGE_LIST' ?
  <UserPackageList  onDifferencePay={onDifferencePay} onPurchase={getPaymentPage} /> :  <AddNewUserPackage transaction={transactionid} isView={isView} onSave={BuyPackage} obj={obj} packageRecord={packageRecord}  />}
          </div> : null}
      </>
    );
}



const mapDispatchToProps = dispatch => {
  return {
    onLoadingStart: () => dispatch(actions.startLoading()),
    onLoadingStop: () => dispatch(actions.stopLoading()),
  };
};

const mapStateToProps = state => {
  return {
    user: state.user.user, 
    permissions:state.user.permissions
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPackagesPage);
