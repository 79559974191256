import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import './TransactionsPage.scss';
import TransactionList from './TransactionList/TransactionList';
import AddNewTransaction from './AddNewTransaction/AddNewTransaction';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../utils/api';
import { getToaster } from '../../utils/helpers';
import { getPagePermission } from '../../utils/helpers';
import { Button } from 'react-materialize';

const TransactionsPage=(props)=>{
  let token = localStorage.getItem('token');
  let [activePage,setactivePage]=useState("TRANSACTION_LIST");
  let [transactionData,setTransactionData]=useState({
    id:0,
    date_time:'',
    user_evidence_file:'',
    amount_money:'',
    transfer_title:'',
    authorization_status:'',
    package:{},
    user:{}
  });
  let [isAddDisable,setisAddDisable]=useState(true);
  let isView=false;
  let [obj,setObj]=useState({
    id:0,
    authorization_status:"",
    approver_evidence_file:"",
    approver_remarks:"",
    transfer_title:""
  });
  const getApprovalPage = (transaction) => {
    setactivePage("APPROVAL_PAGE"); 
    setTransactionData(transaction);
  };
  const ProcessTransaction=(values,e)=>{
    e.preventDefault();
    console.log(values);
   props.onLoadingStart();

    return  axios.post(`${API_URL}/transactions/add`,values, {
      headers: {
        Authorization: `JWT ${token}`,'Content-Type':'application/json'
      },
    })
      .then(async(res) => {     
        let data=res.data;  
         props.onLoadingStop();
         if (data.success) {
           getToaster('Success','transaction processed', data.message);
           setactivePage('TRANSACTION_LIST');
         }else{
           getToaster('Error',"transaction processing error", data.message);
         }
       
      });
  }
  useEffect(()=>{
    if (token == null) {
       props.history.replace('/login');
    }
 
    const changePage = (page) => {
      setactivePage(page);
      if(activePage==="TRANSACTION_LIST"){
         setisAddDisable(true);
      }
      else{
        setObj({
          id:transactionData.id,
          authorization_status:"",
          approver_evidence_file:"",
          approver_remarks:"",
          transfer_title:transactionData.transfer_title
        });
        setisAddDisable(false);
      }
    };
    let permissionData=getPagePermission(props.permissions,"transactions");
    console.log(permissionData); 
      changePage(activePage);
  },[activePage,props.history,props.permissions,token,props.transaction,transactionData]);

  return (
    <>
      {props.user.id ?
   <div className="DataPAGE">
   <div className="DataPAGE__add">
            <nav>
              <ul>
              <li><Button disabled={isAddDisable} onClick={() => setactivePage('APPROVAL_PAGE')}>Transaction Approval</Button></li>
                <li><Button onClick={() => setactivePage('TRANSACTION_LIST')}>Transactions</Button></li>
              </ul>
            </nav>
          </div>
          {activePage === 'TRANSACTION_LIST' ?
<TransactionList onEdit={()=>{}}  onApprovalPage={getApprovalPage} /> :  <AddNewTransaction transaction={transactionData} isView={isView} onSave={ProcessTransaction} obj={obj}   />}
        </div> : null}
    </>
  );
}
const mapStateToProps = state => {
  return {
    user: state.user.user, 
    permissions:state.user.permissions
  }
};
const mapDispatchToProps = dispatch => {
  return {
    onLoadingStart: () => dispatch(actions.startLoading()),
    onLoadingStop: () => dispatch(actions.stopLoading()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage);

