import React, {  useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import UserTransactionList from './UserTransactionList/UserTransactionsList';
import { getToaster } from '../../utils/helpers';
import { Button } from 'react-materialize';
import { API_URL } from '../../utils/api';
import axios from 'axios';
import { getPagePermission } from '../../utils/helpers';
import AddNewUserTransaction from './AddTransaction/AddNewUserTransaction';
const UserTransactionsPage=(props)=>{
  let token = localStorage.getItem('token'); 
  const [isView,]=useState(false);
  const [activePage,setactivePage]=useState('USERTRANSACTION_LIST');

  const [obj,setObj]=useState({
    id_paymentmethod:"",
    user_receiving_number:"",
    amount_money:0,
  });
  const Buytransaction= async (values,e)=>{
    e.preventDefault();
    console.log(values);
   props.onLoadingStart();

    return  axios.post(`${API_URL}/usertransactions/add`,values, {
      headers: {
        Authorization: `JWT ${token}`,'Content-Type':'application/json'
      },
    })
      .then(async(res) => {     
        let data=res.data;  
         props.onLoadingStop();
         if (data.success) {
           getToaster('Success','User transaction payment status', data.message);
           setactivePage('USERTRANSACTION_LIST');
         }else{
           getToaster('Error',"User transaction saving error", data.message);
         }
       
      });
  }

 
  const getPaymentPage=()=>{
    setactivePage("REQUEST_WALLET_TRANSACTION");
  }
  
 


  

  useEffect(()=>{
    if (token == null) {
       props.history.replace('/login');
    }
 
    const changePage = (page) => {
      setactivePage(page);
      if(activePage==="USERTRANSACTION_LIST"){
      //  setisAddDisable(true);
      }
      else{
        setObj({
          id_paymentmethod:"",
          user_receiving_number:"",
          amount_money:0,
        });
     //   setisAddDisable(false);
      }
    };
    let permissionData=getPagePermission(props.permissions,"usertransactions");
    console.log(permissionData); 
      changePage(activePage);
  },[activePage,props.history,props.permissions,token]);


  
    return (
      <>
        {props.user.id ?
     <div className="DataPAGE">
     <div className="DataPAGE__add">
              <nav>
                <ul>
                <li><Button onClick={() => setactivePage('REQUEST_WALLET_TRANSACTION')}>Request Withdrawal</Button></li>
                  <li><Button onClick={() => setactivePage('USERTRANSACTION_LIST')}>Transactions</Button></li>
                </ul>
              </nav>
            </div>
            {activePage === 'USERTRANSACTION_LIST' ?
  <UserTransactionList   onPurchase={getPaymentPage} /> :  <AddNewUserTransaction isView={isView} onSave={Buytransaction} obj={obj}   />}
          </div> : null}
      </>
    );
}



const mapDispatchToProps = dispatch => {
  return {
    onLoadingStart: () => dispatch(actions.startLoading()),
    onLoadingStop: () => dispatch(actions.stopLoading()),
  };
};

const mapStateToProps = state => {
  return {
    user: state.user.user, 
    permissions:state.user.permissions
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTransactionsPage);
