import React from 'react'
import { connect } from 'react-redux';
import CustomDataGrid from '../../../components/DataGrid/CustomDataGrid';
import { useEffect } from 'react';
import { Button } from 'react-materialize';
import { renderTransfer_Tile_Switch } from '../../../utils/helpers';
const TransactionList=(props)=>{

  const columns = [
    {
      field: 'id', headerName: 'ID', width: 70
    },
    {
      field:'user.username',headerName:"User", width:200,
      valueGetter: (params) => {
        return params.row.user!=null ? params.row.user.username:"N/A";
      }
    },
    {
      field: 'id_transaction', headerName: 'Transaction', width: 200,
    },
    {
      field: 'status', headerName: 'status', width: 200,
    },
    {
      field:'View Request',
      headerName:"View Request",
      width: 500,
      renderCell: (params) => { 
        return <Button onClick={()=>props.onEdit(params.row)}>View Request</Button>
      } 
    }
    // {
    //   field:'name',
    //   headerName:"Package",
    //   width: 500,
    //   renderCell: (params) =><PackageCard packageRecord={params.row} onPurchase={()=>props.onPurchase(params.row)} alreadypurchased={params.row.transactions.length || params.row.transactions.length > 0} image="" />   

  ]
  useEffect(()=>{
   // props.homePageStart();
  },[]);
  return (
    <>
      <CustomDataGrid rowHeight={50}  url="complaints" deleteUrl="/complaints/delete" onEdit={props.onEdit} serverListenerUrl="/complaints"  columns={columns} />
</>
  )
}
const mapStateToProps = state => {
  return {
    user: state.user.user,
    bill: state.user.bill,
    transactions: state.user.transactions,
    permissions:state.user.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);
