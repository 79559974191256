import React,{useState,useEffect} from 'react'
import about from '../../assets/LandingPage/images/about.png';
import Slide_1 from '../../assets/LandingPage/images/Slide_1.jpg';
import Slide_2 from '../../assets/LandingPage/images/Slide_2.jpg';
import Slide_3 from '../../assets/LandingPage/images/Slide_3.jpg';
import contact from '../../assets/LandingPage/images/contact.png';
import navLogo from '../../assets/LandingPage/images/navLogo.png';
import faq from '../../assets/LandingPage/images/faq.png';
import '../../assets/LandingPage/css/style.css';
import '../../assets/LandingPage/css/responsive.css';
import './LandingPage.scss';
import 'bootstrap/dist/js/bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import axios from 'axios';
import { API_URL } from '../../utils/api';
function LandingPage(props) {
  const [packages,setpackages]=useState([]);
  const render_packages=()=>{
    return(packages.map((v,i)=>{
      return(
      <div className="col-sm-4 d-flex align-items-stretch mb-3">
         <div className="text-secondary card shadow text-center w-100">
           <div className="bg-theme-dark py-3 px-2 text-white fw-bold rounded-top">
             <h2 className="border-bottom mx-2 mb-3 pb-3">
               <b>{v.name}</b>
             </h2>
             <h4 className="fs-2 mb-0">
              {v.price} <small className="fw-light fs-4">PKR</small>
               <br />
             </h4>
           </div>
           <div className="card-body">
             <ul className="list-style-none p-0">
               <li className="fw-bold border-bottom mb-2 pb-2 fs-5">
                 Plan Details
               </li>
               <li className="border-bottom mb-2 pb-2 ">
                 Daily Reward : {v.reward}
               </li>
               <li className="border-bottom mb-2 pb-2 ">
                 Referral Bonus :{v.referencereward}
               </li>
               <li className="border-bottom mb-2 pb-2 ">
                 Plan Price :  {v.price}
               </li>
               <li className="">Validity : {v.expirydays} Days</li>
             </ul>
             <Link to='/login' className="button-2 text-decoration-none mb-2 mt-1 rounded fs-6 px-4 px-md-3 px-lg-4 d-inline-block text-uppercase">
               Subscribe Now
             </Link>
           </div>
         </div>
       </div> 
       );
       }));
  } 
  useEffect(()=>{
      axios.get(`${API_URL}/getfrontendactivepackages`).then(async(res)=>{
          let data=res.data.data; 
          console.log(data);
          setpackages(data);
      });
  },[]);
  return (
    <>
     
  <nav
    id="home_section"
    className="navbar navbar-expand-md navbar-light bg-white fixed-top shadow py-2"
  >
    <div className="container">
      <a className="navbar-brand" href="#">
        <img
          src={navLogo}
          width=""
          height=""
          alt="logo image"
          className="img-fluid"
        />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse px-3 px-md-0 " id="navbarSupportedContent">
        <ul className="navbar-nav ms-md-0 ms-lg-4">
          <li className="nav-item me-lg-3">
            <a className="nav-link active" aria-current="page" href="#">
              Home
            </a>
          </li>
          <li className="nav-item me-lg-3">
            <a className="nav-link" href="#about_section">
              About
            </a>
          </li>
          <li className="nav-item me-lg-3">
            <a className="nav-link" href="#plans_section">
              Packages
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contact_section">
              Contact
            </a>
          </li>
        </ul>
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex mt-md-2 mt-lg-0">
          <li className="nav-item me-sm-3 mb-2 mb-sm-0">
            <Link className="nav-link"  to="/register">
              Registration
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link button-1 rounded fs-6 px-3 d-inline-block py-0"
              to="/Login"
            >
              Login In
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  {/* End Nav */}
  {/* slider */}
  <div
    id="carouselTopSlider"
    className="carousel slide mt-62"
    data-bs-ride="carousel"
  >
    <div className="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselTopSlider"
        data-bs-slide-to={0}
        className="active"
        aria-current="true"
        aria-label="Slide 1"
      />
      <button
        type="button"
        data-bs-target="#carouselTopSlider"
        data-bs-slide-to={1}
        aria-label="Slide 2"
      />
      <button
        type="button"
        data-bs-target="#carouselTopSlider"
        data-bs-slide-to={2}
        aria-label="Slide 3"
      />
    </div>
    <div className="carousel-inner">
        <div className="carousel-item active">
        <img
          src={Slide_1}
          className="d-block w-100"
          height={500}
          alt="..."
        />
        <div className="carousel-caption d-none d-md-block">
          <h5 className="display-5 fw-bold">Start Earning From The Comfort of Home</h5>
        </div>
     
       </div>
       <div className="carousel-item">
        <img
           src={Slide_2}
          className="d-block w-100"
          height={500}
          alt="..."
        />
        <div className="carousel-caption d-none d-md-block">
          <h5 className="display-5 fw-bold">Best Possible Way for Earn From Home</h5>
        </div>
    </div>

    </div>
    <button
      className="carousel-control-prev"
      type="button"
      data-bs-target="#carouselTopSlider"
      data-bs-slide="prev"
    >
      <span className="carousel-control-prev-icon" aria-hidden="true" />
      <span className="visually-hidden">Previous</span>
    </button>
    <button
      className="carousel-control-next"
      type="button"
      data-bs-target="#carouselTopSlider"
      data-bs-slide="next"
    >
      <span className="carousel-control-next-icon" aria-hidden="true" />
      <span className="visually-hidden">Next</span>
    </button>
  </div>
  {/* slider END */}
  {/* About */}
  <section id="about_section" className="w-100 float-start py-4 py-lg-5">
    <div className="container">
      <div className="row">
        <div className="col-sm-6 d-flex flex-wrap align-content-center">
          <img
            src={about}
            alt="About Us"
            className="img-fluid mx-auto d-block mb-3 mb-sm-0 rounded"
          />
        </div>
        <div className="col-sm-6 d-flex flex-wrap align-content-center text-secondary">
          <h2 className="fs-1 fw-bold text-dark w-100 mb-3">About Us</h2>
          <p>
          Welcome to [Your Investment Company Name], where we are dedicated to helping you navigate the intricate world of investments and financial planning. We understand that the journey to financial security is unique for each individual, and we're here to guide you every step of the way.

At [Your Investment Company Name], we pride ourselves on our commitment to integrity, transparency, and client-centric services. Our team of seasoned financial experts brings a wealth of experience and expertise in various investment strategies and markets, aiming to empower our clients to make informed decisions.
          </p>
          {/* <ul className="ul_1 float_bus p-0 mb-lg-4 position-relative list-style-none">
            <li className="">
              Dolores velit ad excepturi omnis quod nesciunt.
            </li>
            <li className="">
              Dolores velit ad excepturi omnis quod nesciunt.
            </li>
            <li className="">
              Dolores velit ad excepturi omnis quod nesciunt.
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  </section>
  {/* About END */}
  {/* benefits card */}
  <section className="w-100 bg-light float-start py-4 py-lg-5">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h2 className="fs-1 fw-bold text-dark w-100 mb-3">
            Benefits And Facilities
          </h2>
          <p className="text-secondary mb-3 mb-lg-5 w-75 mx-auto">
          Experience tailored investment solutions for your financial goals. 
          Enjoy expert guidance, transparent insights, and personalized strategies. 
          Access a diverse range of investment options to secure your financial future.
          </p>
        </div>
        <div className="col-sm-4 d-flex align-items-stretch mb-3">
          <div className="feature-card text-secondary card p-3 border-0 shadow text-center w-100">
            <div className="text-center display-2 text-theme">
              <i className="fas fa-copy" />
            </div>
            <div className="card-body">
              <h5 className="card-title fw-bold">We're Certified</h5>
              <p className="card-text">
              Rest assured, our team holds esteemed certifications in financial planning and investment management. Our expertise is validated, ensuring reliable guidance for your financial goals.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-4 d-flex align-items-stretch mb-3">
          <div className="feature-card text-secondary card p-3 border-0 shadow text-center w-100">
            <div className="text-center display-2 text-theme">
              <i className="fas fa-link" />
            </div>
            <div className="card-body">
              <h5 className="card-title fw-bold">We're Secure</h5>
              <p className="card-text">Your security is paramount: we employ cutting-edge encryption and robust measures to safeguard your investments and personal information. Trust in our secure platform for a worry-free investment experience.</p>
            </div>
          </div>
        </div>
        <div className="col-sm-4 d-flex align-items-stretch mb-3">
          <div className="feature-card text-secondary card p-3 border-0 shadow text-center w-100">
            <div className="text-center display-2 text-theme">
              <i className="fas fa-coins" />
            </div>
            <div className="card-body">
              <h5 className="card-title fw-bold">We're Profitable</h5>
              <p className="card-text">
              Our track record speaks volumes: consistently delivering profitable returns for our investors. Join us to capitalize on lucrative investment opportunities and secure financial growth.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-4 d-flex align-items-stretch mb-3">
          <div className="feature-card text-secondary card p-3 border-0 shadow text-center w-100">
            <div className="text-center display-2 text-theme">
              <i className="fas fa-refresh" />
            </div>
            <div className="card-body">
              <h5 className="card-title fw-bold">We Accept Crypto</h5>
              <p className="card-text">
              Embrace the future of finance with us—easily invest using various cryptocurrencies. Diversify your portfolio by seamlessly transacting in crypto on our platform.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-4 d-flex align-items-stretch mb-3">
          <div className="feature-card text-secondary card p-3 border-0 shadow text-center w-100">
            <div className="text-center display-2 text-theme">
              <i className="fas fa-desktop" />
            </div>
            <div className="card-body">
              <h5 className="card-title fw-bold">Best Support</h5>
              <p className="card-text">Experience unparalleled support with our dedicated team available around the clock. Count on swift, knowledgeable assistance to address your investment inquiries and needs.</p>
            </div>
          </div>
        </div>
        <div className="col-sm-4 d-flex align-items-stretch mb-3">
          <div className="feature-card text-secondary card p-3 border-0 shadow text-center w-100">
            <div className="text-center display-2 text-theme">
              <i className="fas fa-check" />
            </div>
            <div className="card-body">
              <h5 className="card-title fw-bold">We're Global</h5>
              <p className="card-text">
              Explore investment opportunities worldwide with our extensive global reach. Benefit from our network and insights across diverse markets for diversified and international portfolio options.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* benefits card END */}
  {/* Plans card */}
  <section
    id="plans_section"
    className="w-100 bg-white float-start py-4 py-lg-5"
  >
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h2 className="fs-1 fw-bold text-dark w-100 mb-3">
            Membership Plans
          </h2>
          <p className="text-secondary mb-3 mb-lg-5 w-75 mx-auto">
            Best Possible Way for Earn From Home. Temporibus eveniet commodi
            obcaecati voluptates reiciendis quis ipsum incidunt quibusdam
            aperiam autem suscipit maiores temporTemporibus eveniet commodi
            obcaecati voluptates reiciendis.
          </p>
        </div>
        {
          render_packages()
        }
        {/* <div className="col-sm-4 d-flex align-items-stretch mb-3">
          <div className="text-secondary card shadow text-center w-100">
            <div className="bg-theme-dark py-3 px-2 text-white fw-bold rounded-top">
              <h2 className="border-bottom mx-2 mb-3 pb-3">
                <b>Basic Plan</b>
              </h2>
              <h4 className="fs-2 mb-0">
                1,500.00 <small className="fw-light fs-4">PKR</small>
                <br />
              </h4>
            </div>
            <div className="card-body">
              <ul className="list-style-none p-0">
                <li className="fw-bold border-bottom mb-2 pb-2 fs-5">
                  Plan Details
                </li>
                <li className="border-bottom mb-2 pb-2 ">
                  Daily Limit : 2 PTC
                </li>
                <li className="border-bottom mb-2 pb-2 ">
                  Referral Bonus : Upto 3 Level
                </li>
                <li className="border-bottom mb-2 pb-2 ">
                  Plan Price : 1,500.00 pkr
                </li>
                <li className="">Validity : 50 Days</li>
              </ul>
              <button className="button-2 mb-2 mt-1 rounded fs-6 px-4 px-md-3 px-lg-4 d-inline-block text-uppercase">
                Subscribe Now
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-4 d-flex align-items-stretch mb-3">
          <div className="text-secondary card shadow text-center w-100">
            <div className="bg-theme-dark py-3 px-2 text-white fw-bold rounded-top">
              <h2 className="border-bottom mx-2 mb-3 pb-3">
                <b>King of Plan</b>
              </h2>
              <h4 className="fs-2 mb-0">
                1,500.00 <small className="fw-light fs-4">PKR</small>
                <br />
              </h4>
            </div>
            <div className="card-body">
              <ul className="list-style-none p-0">
                <li className="fw-bold border-bottom mb-2 pb-2 fs-5">
                  Plan Details
                </li>
                <li className="border-bottom mb-2 pb-2 ">
                  Daily Limit : 2 PTC
                </li>
                <li className="border-bottom mb-2 pb-2 ">
                  Referral Bonus : Upto 3 Level
                </li>
                <li className="border-bottom mb-2 pb-2 ">
                  Plan Price : 1,500.00 pkr
                </li>
                <li className="">Validity : 50 Days</li>
              </ul>
              <button className="button-2 mb-2 mt-1 rounded fs-6 px-4 px-md-3 px-lg-4 d-inline-block text-uppercase">
                Subscribe Now
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  </section>
  {/* Plans card END */}
  {/* Faq */}
  <section className="w-100 bg-light float-start py-4 py-lg-5">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h2 className="fs-1 fw-bold text-dark w-100 mb-3">
            Frequently Asked Question
          </h2>
          <p className="text-secondary mb-3 mb-lg-5 w-75 mx-auto">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus
            necessitatibus repudiandae porro reprehenderit, beatae perferendis
            repellat quo ipsa omnis, vitae!
          </p>
        </div>
        <div className="col-sm-5">
          <img
            src={faq}
            alt="About Us"
            className="img-fluid mx-auto d-block mb-3 mb-sm-0"
          />
        </div>
        <div className="col-sm-7">
          <div className="accordion w-100" id="accordionFaq">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Question will be here ?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionFaq"
              >
                <div className="accordion-body">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Doloribus necessitatibus repudiandae porro reprehenderit,
                  beatae perferendis repellat quo ipsa omnis, vitae!
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Question will be here ?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionFaq"
              >
                <div className="accordion-body">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Doloribus necessitatibus repudiandae porro reprehenderit,
                  beatae perferendis repellat quo ipsa omnis, vitae!
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Question will be here ?
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionFaq"
              >
                <div className="accordion-body">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Doloribus necessitatibus repudiandae porro reprehenderit,
                  beatae perferendis repellat quo ipsa omnis, vitae!
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Question will be here ?
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionFaq"
              >
                <div className="accordion-body">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Doloribus necessitatibus repudiandae porro reprehenderit,
                  beatae perferendis repellat quo ipsa omnis, vitae!
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Question will be here ?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionFaq"
              >
                <div className="accordion-body">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Doloribus necessitatibus repudiandae porro reprehenderit,
                  beatae perferendis repellat quo ipsa omnis, vitae!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Faq END */}
  {/* contact */}
  <section
    id="contact_section"
    className="w-100 bg-white float-start py-4 py-lg-5"
  >
    <div className="container">
      <div className="row">
        <div className="col-sm-5 d-flex flex-wrap align-content-center">
          <form className="bg-white p-3 py-md-4 px-md-3 px-lg-4 mb-3 mb-sm-0 shadow border rounded w-100">
            <h2 className="fs-3 fw-bold text-dark w-100">
              Get in touch with us
            </h2>
            <p className="text-secondary">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            </p>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control myinput fw-light"
                placeholder="Enter your name"
              />
            </div>
            <div className="input-group mb-3">
              <input
                type="email"
                className="form-control myinput fw-light"
                placeholder="Enter your email"
              />
            </div>
            <div className="mb-3">
              <textarea
                className="form-control myinput h-auto fw-light"
                placeholder="Enter your message"
                id=""
                rows={3}
                defaultValue={""}
              />
            </div>
            <div className="text-center">
              <button className="button-2 rounded fs-6 px-4 px-md-3 px-lg-4 d-inline-block text-uppercase">
                Submit Now
              </button>
            </div>
          </form>
        </div>
        <div className="col-sm-7 d-flex flex-wrap align-content-center">
          <img
            src={contact}
            alt="contact Us"
            className="img-fluid mx-auto d-block mb-3 mb-sm-0"
          />
        </div>
      </div>
    </div>
  </section>
  {/* contact END */}
  {/* footer */}
  <footer className="bg-dark py-3 py-lg-4 text-white float-start w-100">
    <div className="container">
      <div className="row m-0">
        <div className="col-12">
          <p className="m-0 text-center">
            Copyright © {new Date().getFullYear()} . All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </footer>
  {/* footer END */}
</>
  )
}

export default LandingPage