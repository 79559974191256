import React from 'react'
import { connect } from 'react-redux';
import CustomDataGrid from '../../../components/DataGrid/CustomDataGrid';
import * as actions from '../../../store/actions';
import { useEffect } from 'react';

const UsersList=(props)=>{

  const columns = [
    {
      field: 'id', headerName: 'ID', width: 70
    },
    {
      field: 'name', headerName: 'Name', width: 200
    },
    {
      field: 'price', headerName: 'Price', width: 200
    },
    {
      field: 'expirydays', headerName: 'Expiry Days', width: 200
    },
    {
      field: 'reward', headerName: 'Reward', width: 200
    },
    {
      field: 'referencereward', headerName: 'Reference Reward', width: 200
    },{
      field:'status',
      headerName:'Status',
      width: 200
    },{
      field:'Action'
    }
    // ,
    // {
    //   field: 'email', headerName: 'Email Address', width: 200
    // }
  ]
  useEffect(()=>{
   // props.homePageStart();
  },[]);
  return (
    <>
      <CustomDataGrid  url="packages" deleteUrl="/packages/delete" onEdit={props.onEdit} serverListenerUrl="/packages"  columns={columns} />
</>
  )
}
const mapStateToProps = state => {
  return {
    user: state.user.user,
    bill: state.user.bill,
    transactions: state.user.transactions,
    permissions:state.user.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGettingTransaction: (token, uId) => dispatch(actions.gettingTransactions(token, uId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
