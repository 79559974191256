import axios from 'axios';
import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../utils/api';
const ValidatedUser = props => {
  const [doNotExist,setdoNotExist]=useState(true);
  const [error,seterror]=useState("");
  const [user,setuser]=useState({
        username:"",
        email:"",
        name:""
  });
  useEffect(()=>{
    props.onStartLoading(); 
    axios.post(`${API_URL}/register/validate`, {ValidationCode:props.validationcode})
    .then(res => {
      console.log(res.data);
      props.onStopLoading();
      setdoNotExist(false);
      setuser(res.data);
    })
    .catch(err => {
      props.onStopLoading();
      console.log(err.response.data);
      setdoNotExist(true);
      seterror(err.response.data.error);
    });
  },[]);
  //TODO: better styling
  return (
    <div className="new-user-info">
      {
        doNotExist ? <><span className='error'>{error}</span></> :
        <>
      <h3>Your account validated successfully</h3>
      <div>
        <div>{user.username}</div>
        <div>{user.email}</div>
        <div>{user.name}</div>
      </div>
      <Link to="/login">Go to Sign In</Link>
      </>
     }
    </div>
  );
};

export default ValidatedUser;
