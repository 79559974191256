import React from 'react'
import { connect } from 'react-redux';
import CustomDataGrid from '../../../components/DataGrid/CustomDataGrid';
import { useEffect } from 'react';
import { Button } from 'react-materialize';
import { renderTransfer_Tile_Switch } from '../../../utils/helpers';
const TransactionList=(props)=>{

  const columns = [
    {
      field: 'id', headerName: 'ID', width: 70
    },
    {
      field:'user.username',headerName:"User", width:200,
      valueGetter: (params) => {
        return params.row.user!=null ? params.row.user.username:"N/A";
      }
    },
    {
      field: 'amount_money', headerName: 'Amount', width: 200
    },
    {
      field: "package.name",
      headerName: "Package",
      width: 200,
      valueGetter: (params) => {
        return params.row.package!=null ? params.row.package.name:"N/A";
      }
    },
    {
      field:'transfer_title',headerName:'Transfer Type',width:200,
      renderCell:(params)=><span className='btn btn-secondary'>  {renderTransfer_Tile_Switch(params.row.transfer_title)}</span>
    
    },
    {
      field:'authorization_status',headerName:'Status',width:200
    },
    {
      field:'View Request',
      headerName:"View Request",
      width: 500,
      renderCell: (params) =>params.row.authorization_status==='Pending' && params.row.transfer_title!=='Wallet_Deposit' ? <Button className="btn" onClick={()=>props.onApprovalPage(params.row)}>Process Transaction</Button>:<span>Already processed</span>
    }
    // {
    //   field:'name',
    //   headerName:"Package",
    //   width: 500,
    //   renderCell: (params) =><PackageCard packageRecord={params.row} onPurchase={()=>props.onPurchase(params.row)} alreadypurchased={params.row.transactions.length || params.row.transactions.length > 0} image="" />   

  ]
  useEffect(()=>{
   // props.homePageStart();
  },[]);
  return (
    <>
      <CustomDataGrid rowHeight={50}  url="transactions" deleteUrl="/transactions/delete" onEdit={props.onEdit} serverListenerUrl="/transactions"  columns={columns} />
</>
  )
}
const mapStateToProps = state => {
  return {
    user: state.user.user,
    bill: state.user.bill,
    transactions: state.user.transactions,
    permissions:state.user.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);
