import React from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { API_URL } from '../../utils/api';
import { renderTransfer_Tile_Switch } from '../../utils/helpers';
export default function TransactionCard(props) {
  const {transaction}=props;
  return (
    <Card sx={{ maxWidth: 800, }}>
      {
        transaction.user_evidence_file!==undefined && transaction.user_evidence_file!==null &&
      <CardMedia
        sx={{ height: 140,width: '140px',margin:'0 auto' }}
        image={`${API_URL}/paymentreceipts/${transaction.user_evidence_file}`}
        title="green iguana"
        component='img'
      />

      }
      <CardContent>
        {transaction.transfer_title==='Wallet_Withdraw' && <Typography gutterBottom variant="h6" color="text.primary" component="div">
         Receiver Account Number : {transaction.user_receiving_number}
        </Typography>}
       <Typography gutterBottom variant="body2" component="div">
         Payment Method : {transaction.paymentmethod.name}
        </Typography>
        {transaction.transfer_title==='Package_Purchase' && <Typography gutterBottom variant="body2" component="div">
         Transferred Account Number : {transaction.paymentmethod.associatednumber}
        </Typography>}
        <Typography gutterBottom variant="body2" component="div">
         Client Name : {transaction.user.name} 
        </Typography>
        <Typography gutterBottom variant="body2" component="div">
         Client Username : {transaction.user.username} 
        </Typography>
        <Typography variant="body2" color="text.primary">
         Amount : {transaction.amount_money}
        </Typography>
        <Typography variant="body2" color="text.secondary">
         Transaction Type : {renderTransfer_Tile_Switch(transaction.transfer_title)} 
        </Typography>
      </CardContent>
    </Card>
  );
}
