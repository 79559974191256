import React from 'react'
import { connect } from 'react-redux';
import CustomDataGrid from '../../../components/DataGrid/CustomDataGrid';
import * as actions from '../../../store/actions';
import { useEffect } from 'react';
import { Button } from 'react-materialize';
import {CustomTabPanel,a11yProps} from '../../../components/Tabs/CustomTabPanel';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
const UserTaskList=(props)=>{
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 const getTaskPage=(row)=>{
  props.onTask(row)
 }
  var columns = [
    {
      field:'',
      headerName:"View Request",
      width: 500,
      renderCell: (params) => { 
        console.log(params.row.task.usertasks.filter(x=>x.id_package===params.row.package.id));
        return params.row.task.usertasks.filter(x=>x.id_package===params.row.package.id).length<=0 ? <Button className="btn" onClick={()=>getTaskPage(params.row)}>start task</Button>: params.row.task.usertasks.filter(x=>x.id_package===params.row.package.id)[0].usertaskdetails.length===params.row.task.taskdetails.length || params.row.task.usertasks.filter(x=>x.id_package===params.row.package.id)[0].status==='Completed' ?  <span>Task completed</span> :<Button onClick={()=>getTaskPage(params.row)}>Resume task</Button>}
    },
    {
      field: '`task`.`title`', headerName: 'Title', width: 200,
      renderCell:(params)=>params.row.task.title
    },
    {
      field: '`package`.`name`', headerName: 'Package', width: 200,
      renderCell:(params)=>params.row.package.name
    },{
      field: '`package`.`reward`', headerName: 'Reward', width: 200,
      renderCell:(params)=>params.row.package.reward
    }
  ]
  var columnHistory=[
    {
      field: '`task`.`title`', headerName: 'Title', width: 200,
      renderCell:(params)=>params.row.task.title
    },
    {
      field: '`package`.`name`', headerName: 'Package', width: 200,
      renderCell:(params)=>params.row.package.name
    },{
      field: '`package`.`reward`', headerName: 'Reward', width: 200,
      renderCell:(params)=>params.row.transactions!==undefined ? params.row.transactions[0].amount_money:0
    }
  ]
  useEffect(()=>{
   // props.homePageStart();
  },[]);
  return (
    <Box sx={{ width: '100%' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} aria-label="Tasks">
        <Tab label="Current Tasks" {...a11yProps(0)} />
        <Tab label="Task History" {...a11yProps(1)} />
      </Tabs>
    </Box>
    <CustomTabPanel value={value} index={0}>
      <CustomDataGrid rowHeight={50}  url="usertasks" deleteUrl="/usertasks/delete" onEdit={props.onEdit} serverListenerUrl="/usertasks"  columns={columns} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <CustomDataGrid rowHeight={50}  url="usertasks" deleteUrl="/usertaskhistory/delete" serverListenerUrl="/usertasks/usertaskhistory"  columns={columnHistory} />
      </CustomTabPanel>
      </Box>
  )
}
const mapStateToProps = state => {
  return {
    user: state.user.user,
    bill: state.user.bill,
    transactions: state.user.transactions,
    permissions:state.user.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGettingTransaction: (token, uId) => dispatch(actions.gettingTransactions(token, uId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserTaskList);
