import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { TextField } from '@material-ui/core';
import Button from '../../../components/Button/Button';
import * as yup from 'yup'; // for everything
const RegisterForm = props => {
//  console.log(props.reference_key);
  const formik = useFormik({
    isInitialValid:false,
    initialValues:{
      username: '',
      password: '',
      email: '',
      name: '',
      reference_key:props.reference_key!==undefined  ? props.reference_key:''
    },
    validationSchema:yup.object({
      email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
      name: yup
        .string('Enter your name')
        .required('Name is required'),
      password: yup
        .string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
      username: yup
        .string('Enter your username')
        .required('Username is required'),
      reference_key:yup.string("Enter reference key")
    }),
    
    onSubmit:(values, actions ) => {
    //  console.log(customParams);
    //  onFormSubmit(values,actions)
    }});
    const onFormSubmit =(data,customParams,form) => {
      customParams.registerUser(data,form);
      // async request which may result error
      try {
        // await fetch()
      } catch (e) {
        // handle your error
      }
    }
    useEffect(()=>{
  //    formik.values.reference_key=props.reference_key!==undefined ? props.reference_key:''
      return ()=>{
          formik.isInitialValid=false
      };
    },[formik,props.reference_key])
  return (
 
    <form onSubmit={form => { onFormSubmit(formik.values,props, form); }}>
          <div className="content__form__form">
            <div className="content__form__form__account-info">
              <TextField  fullWidth type="text" name="username" value={formik.values.username} placeholder="Username (i.e. 12345)"
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     error={formik.touched.username && Boolean(formik.errors.username)}
                     helperText={formik.touched.username && formik.errors.username}/>
              <TextField fullWidth type="password" name="password" value={formik.values.password} placeholder="Password"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={formik.touched.password && Boolean(formik.errors.password)}
                       helperText={formik.touched.password && formik.errors.password}/>
              <TextField fullWidth  type="text" name="email" value={formik.values.email} placeholder="Email"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={formik.touched.email && Boolean(formik.errors.email)}
                       helperText={formik.touched.email && formik.errors.email}/>
                       <TextField fullWidth type="text" name="name" value={formik.values.name} placeholder="Name"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={formik.touched.name && Boolean(formik.errors.name)}
                       helperText={formik.touched.name && formik.errors.name}/>
                
              <TextField fullWidth type="text"  name="reference_key" value={formik.values.reference_key} placeholder="Reference Key"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       error={formik.touched.reference_key && Boolean(formik.errors.reference_key)}
                       helperText={formik.touched.reference_key && formik.errors.reference_key}/>
            </div>
          </div>
          <div className="content__form__error">
            {props.error}
          </div>
          <div className="content__form__register-btn">

          <Button  type="submit" disabled={!formik.isValid || props.isView }> Register</Button>
          </div>
        </form>
    
  );
};

export default RegisterForm;
