
import PreviewFile from "./PreviewFile";
import React, { useEffect, useState } from 'react';
import { getAllowedExt } from "../../utils/helpers";
import './FileInputButton.scss';

const FileUploadButton = (formik, inputName,text) => {
    const [dragActive,setDragActive]=useState(false)
    console.log(inputName);
    let allowedExts = getAllowedExt(inputName);
    const handleDrag = function(e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };
    const handleDragOver = (e) => {
      let event = e;
      event.stopPropagation();
      event.preventDefault();
  }
    
    // triggers when file is dropped
    const handleDrop = function(e) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        formik.setFieldValue(inputName, e.dataTransfer.files[0]);
      }
    };
    useEffect(()=>{

    },[])
    return (<><div className="FileUpload_Box" onDragEnter={handleDrag}>
      <div className="DragableFileUpload">
        
      <svg className="UploadIcon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloudUploadIcon"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
      <span className="UploadIconTypography">Drag 'n' drop some files here, or click to select files. Files accepted are : {allowedExts}</span>
       
        <label className="UploadButton" htmlFor={inputName}>
          <span>{text}</span>
        </label>
        <input
          id={inputName}
          name={inputName}
          type="file"
          accept={allowedExts}
          onChange={(event) => {
            formik.setFieldValue(inputName, event.currentTarget.files[0]);
          }}
        />
        {formik.values[inputName] ? 
          <div className="PreviewBlock"><PreviewFile className={{ margin: 'auto' }} width={250} height={"auto"} file={formik.values[inputName]} /><div onClick={()=> formik.setFieldValue(inputName, null)} className="deletePreviewFile"><svg className="FileUploadPreviewDeleteOption" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CancelIcon"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg></div>
          </div>
         : null}
      </div>
     

      { dragActive && <div className="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDragOver} onDrop={handleDrop}></div> }
    </div>
     <p className="inputFieldValidationError">{formik.errors[inputName]}</p>
     </>
    )
  }

export default FileUploadButton;