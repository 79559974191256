import React from 'react';
import { NavLink } from 'react-router-dom';
import './Nav.scss'
import Logo from '../Logo/Logo';
import CloseIcon from '@material-ui/icons/Close';
import { useState,useEffect } from 'react';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SyncLockOutlinedIcon from '@mui/icons-material/SyncLockOutlined';
import { WhatsApp } from '@mui/icons-material';
import {Grid} from '@mui/material';
const Nav = props =>{
  const [navSize, setnavSize] = useState("10rem");
  const [navColor, setnavColor] = useState("transparent");
  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#252734") : setnavColor("transparent");
    window.scrollY > 10 ? setnavSize("5rem") : setnavSize("10rem");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const show = props.show?'Navigation show':'Navigation';

  return(
    <div className={show}>
      <CloseIcon onClick={props.close} className="close-icon"/>
      <div className="Navigation__logo">
        <Logo/>
      </div>
      <nav className="Navigation__menu">
        <ul>
          <li><NavLink onClick={props.close} exact activeClassName="activeLink" to="/">Home</NavLink></li>
          {props.permissions.map((v,i)=>{
          return <li key={i}><NavLink  onClick={props.close} exact activeClassName="activeLink" to={'/'+v.menu.accessurl}>{v.menu.name}</NavLink></li>
          })
          }
         
        </ul>
      </nav>
      <div className="Navigation__footer">
      <Grid style={{textAlign:"center"}} container spacing={{ xs: 1 , md: 1 }} columns={{ xs: 4, sm: 12, md: 12 }}>
      <Grid item xs={1} md={3} sm={2}>
      <div>
      <NavLink onClick={props.close} className='btn padding-8' exact activeClassName="activeLink" to="/settings"><SettingsOutlinedIcon/></NavLink>
      </div>
      </Grid>

      <Grid item xs={1} md={3} sm={2}>
      <div>
      <NavLink onClick={props.close} className='btn padding-8' exact activeClassName="activeLink" to="/changepassword"><SyncLockOutlinedIcon/></NavLink>
      </div>
      </Grid>
      <Grid item xs={1} md={3} sm={2}>
      <div>
      <NavLink onClick={props.close} className='btn padding-8' exact activeClassName="activeLink" to={{pathname: "https://chat.whatsapp.com/E96RzsHLSu31oUigqDYx2Q"}} target="_blank"><WhatsApp/></NavLink>
      </div>
      </Grid>
     
     
      <Grid item xs={1} md={3} sm={2} >
      <div>
       <NavLink onClick={props.close} className='btn padding-8' exact activeClassName="activeLink" to="/logout"><LogoutOutlinedIcon/></NavLink>
       </div>
       </Grid>
        </Grid>
    </div>
    </div>
  )
}

export default Nav;
