import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { TextField,Switch, Typography  } from '@material-ui/core';
import * as yup from 'yup'; // for everything
import { useState } from 'react';
import Button from '../../../components/Button/Button';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
const AddNewComplaint = (props) => {
  const [initialValues]=useState(props.obj);
  const formik = useFormik({
  isInitialValid:props.obj.id===0 ? false:true,
  validateOnMount:props.obj.id===0 ? false:true,
  initialValues:initialValues,
  validationSchema:yup.object({
    id:yup
    .number(),
    id_transaction: yup
    .number()
    .required('Transaction Id id required'),
    description: yup
    .string('Enter status')
    .required('Description is required'),
    status:yup
    .string('Enter status')
    .required('Status is required'),
    response:yup
    .string('Enter response').nullable()
  }),
  
  onSubmit:(values, actions ) => {
  //  console.log(customParams);
  //  onFormSubmit(values,actions)
  }});
  const onFormSubmit =(data,customParams,form) => {
    customParams.SaveComplaint(data,form);
    try {
      // await fetch()
    } catch (e) {
      // handle your error
    }
  }
  useEffect(()=>{
    
  },[]);
  
  return (
    <div className="DataPAGE__form">
          <form onSubmit={form => { onFormSubmit(formik.values,props, form); }}>
          <TextField  onChange={formik.handleChange} disabled={props.isView} type='hidden' id="id" name="id" value={formik.values.id} />
   
                <TextField disabled={true} 
          fullWidth 
          type="number"
          label="Transaction"
          id="id_transaction"
          name="id_transaction"
          required
          inputProps={{ min: 0 }}
          value={formik.values.id_transaction}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.id_transaction && Boolean(formik.errors.id_transaction)}
          helperText={formik.touched.id_transaction && formik.errors.id_transaction}
        />
         
          <TextField disabled={true} 
          fullWidth 
          type="text"
          label="Description"
          required
          multiline
          rows={4}
          id="description"
          name="description"
          inputProps={{ min: 0 }}
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />
          <TextField disabled={props.isView} 
          fullWidth 
          type="text"
          label="Response"
          required
          multiline
          rows={4}
          id="response"
          name="response"
          inputProps={{ min: 0 }}
          value={formik.values.response}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.response && Boolean(formik.errors.response)}
          helperText={formik.touched.response && formik.errors.response}
        />
         <br />
         <br />
        <FormControl fullWidth>
          <InputLabel id="Status">Status</InputLabel>
          <Select
            labelId="Status"
            id="Status"
            name='status'
            label="Status"
            disabled={props.isView}
            value={formik.values.status}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.status && Boolean(formik.errors.status)}
          >
          <MenuItem key={1} value={""}>Select</MenuItem>
          <MenuItem key={2} value={"Approved"}>Approved</MenuItem>
          <MenuItem key={3} value={"Rejected"}>Rejected</MenuItem>
          </Select>
        </FormControl>
        <br />

        <br/>
        <Typography align='right'>
            <Button  type="submit" disabled={!formik.isValid || props.isView }><SaveOutlined />  Save Complaint</Button>
            </Typography>
          </form>
    </div>
  );
};

export default AddNewComplaint;
