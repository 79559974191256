import React, {  useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import UserList from './UserList/UsersList';
import AddNewUser from './AddUser/AddNewUser';
import { getToaster } from '../../utils/helpers';
import { Button } from 'react-materialize';
import { API_URL } from '../../utils/api';
import axios from 'axios';
import { getPagePermission } from '../../utils/helpers';
const UsersPage=(props)=>{
  let token = localStorage.getItem('token');
  let [data_text,setdata_text]=useState('Add new');  
  const [activePage,setactivePage]=useState('USER_LIST');
  const [isView,setisView]=useState(false);
  const [isEdit,setisEdit]=useState(false);
  const [isAddDisable,setisAddDisable]=useState(false);
  const [obj,setObj]=useState({
    id: 0,
    username: '',
    password: '',
    email: '',
    name:'',
    status:'Disable'
  });
  const SaveUser= async (values,e)=>{
    e.preventDefault();
    props.onLoadingStart();

    return  axios.post(`${API_URL}/users/${values.id===0 ? 'add':'edit'}`,values, {
      headers: {
        Authorization: `JWT ${token}`,'Content-Type':'application/json'
      },
    })
      .then(async(res) => {     
        let data=res.data;  
         props.onLoadingStop();
         if (data.success) {
           getToaster('Success','User save status', data.message);
           setdata_text('Add new')
           setactivePage('USER_LIST');
         }else{
           getToaster('Error',"User saving error", data.message);
         }
       
      });
  }
  const OnEdit=async(id,isDataView)=>{
    isDataView  ? setisView(true) :setisView(false);
    props.onLoadingStart();
    await axios.get(`${API_URL}/users/edit?id=${id}`,{
      headers: {
        Authorization: `JWT ${token}`
      }
    }
    ).then(async(res)=>{
       let data=res.data;  
       console.log(data);
        props.onLoadingStop();
          setObj(data.data);
          isDataView ? setdata_text("View"):setdata_text('Edit');
          setisEdit(true);
          setactivePage('ADD_NEW_USER');
      })
  }
 
  const getUserPage=()=>{
    setactivePage("USER_LIST");
  }
  
 


  

  useEffect(()=>{
    if (token == null) {
       props.history.replace('/login');
    }
 
    const changePage = (page) => {
      setactivePage(page);
      if(activePage==="USER_LIST"){
        setObj({
          id: 0,
          username: '',
          password: '',
          email: '',
          name:'',
          status:'Disable'
        })
        setdata_text("Add new");
        setisEdit(false); 
        setisView(false);
      }
      else{
      }
    };
    let permissionData=getPagePermission(props.permissions,"users");
    console.log(permissionData); 
      permissionData!==undefined  && setisAddDisable((permissionData.type==="All" || permissionData.type.includes("Add")));
      changePage(activePage);
      isEdit && setisAddDisable(true);
  },[activePage,isEdit,props.history,props.permissions,token]);


  
    return (
      <>
        {props.user.id ?
     <div className="DataPAGE">
     <div className="DataPAGE__add">
              <nav>
                <ul>
                <li><Button disabled={!isAddDisable} onClick={() => setactivePage('ADD_NEW_USER')}>{data_text} user</Button></li>
                  <li><Button onClick={() => setactivePage('USER_LIST')}>User list</Button></li>
                </ul>
              </nav>
            </div>
            {activePage === 'USER_LIST' ?
  <UserList onDeleteSuccess={()=>getUserPage} onEdit={()=>OnEdit} /> :  <AddNewUser isView={isView} onSave={SaveUser} obj={obj}  />}
          </div> : null}
      </>
    );
}



const mapDispatchToProps = dispatch => {
  return {
    onLoadingStart: () => dispatch(actions.startLoading()),
    onLoadingStop: () => dispatch(actions.stopLoading()),
  };
};

const mapStateToProps = state => {
  return {
    user: state.user.user, 
    permissions:state.user.permissions
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
