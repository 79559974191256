import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { TextField,Switch,Typography } from '@material-ui/core';
import * as yup from 'yup'; // for everything
import { useState } from 'react';
import Button from '../../../components/Button/Button';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import { handleSwitchChange } from '../../../utils/helpers';
const AddNewUser = (props) => {
  const [initialValues]=useState(props.obj);
  const formik = useFormik({
  isInitialValid:props.obj.id===0 ? false:true,
  initialValues:initialValues,
  validationSchema:yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    name: yup
      .string('Enter your name')
      .required('Name is required'),
    password: yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
    username: yup
      .string('Enter your username')
      .required('Username is required'),
    status: yup
      .string('Enter status')
      .required('Status is required'),
  }),
  
  onSubmit:(values, actions ) => {
  //  console.log(customParams);
  //  onFormSubmit(values,actions)
  }});
  const onFormSubmit =(data,customParams,form) => {
    customParams.onSave(data,form);
    // async request which may result error
    try {
      // await fetch()
    } catch (e) {
      // handle your error
    }
  }
  useEffect(()=>{
    
  },[])
  return (
    <div className="DataPAGE__form">
          <form onSubmit={form => { onFormSubmit(formik.values,props, form); }}>
            <TextField disabled={props.isView} type='hidden' id="id" name="id" value={formik.values.id} />
            <TextField disabled={props.isView}  fullWidth type="text" name="email" id="email" label="Email" value={formik.values.email}
                   placeholder="Email" onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   error={formik.touched.email && Boolean(formik.errors.email)}
                   helperText={formik.touched.email && formik.errors.email}
                   />
        <TextField disabled={props.isView} 
          fullWidth
          id="username"
          name="username"
          label="Username"
          type="text"
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
        <TextField disabled={props.isView} 
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
            <TextField disabled={props.isView} 
          fullWidth
          id="name"
          name="name"
          label="Name"
          type="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
         <FormControlLabel  label="status" control={<Switch value={formik.values.status} disabled={props.isView}  onChange={(e)=>handleSwitchChange(e,formik,'Enable','Disable')}  defaultChecked={formik.values.status==='Enable'? true:false} inputProps={{ 'aria-label': 'Status' }} />}  />
       
        <br/>
        <Typography align='right'>
            <Button  type="submit" disabled={!formik.isValid || props.isView }><SaveOutlined /> Save User</Button>
            </Typography>
          </form>
    </div>
  );
};

export default AddNewUser;
