import React from 'react'
import { DataGrid } from '@mui/x-data-grid'

const LocalDataGrid=({rows,columns,rowHeight})=>{
  return (
    <DataGrid
    rows={rows}
    columns={columns}
    rowHeight={rowHeight}
    initialState={{
      pagination: {
        paginationModel: { page: 0, pageSize: 3 },
      },
    }}
    pageSizeOptions={[3, 5]}
  />
  )
}

export default LocalDataGrid