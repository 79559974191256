import React,{useEffect,useState} from 'react'
import Countdown from "react-countdown";
import { getLocalStorageValue } from '../../utils/helpers';
function Counter(props) {
    const [data, setData] = useState(
        { date: Date.now(), delay: props.time } //60 seconds
      );
      const wantedDelay = props.time; //60 s
      const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a complete state
          return <></>
        } else {
          // Render a countdown
          return (
            <span>
              {hours}:{minutes}:{seconds}
            </span>
          );
        }
      };
      useEffect(() => {
        const savedDate = getLocalStorageValue(props.end_date);
        if (savedDate != null && !isNaN(savedDate)) {
          const currentTime = Date.now();
          const delta = parseInt(savedDate, 10) - currentTime;
      
          //Do you reach the end?
          if (delta > wantedDelay) {
            //Yes we clear uour saved end date
            if (localStorage.getItem(props.end_date).length > 0)
              localStorage.removeItem(props.end_date);
              props.enableBtn(false);
            } else {
            //No update the end date  
            setData({ date: currentTime, delay: delta });
            }
        }else{
            props.enableBtn(false);
        }
      }, []);
  return (
    <Countdown
date={data.date + data.delay}
renderer={renderer}
onStart={(delta) => {
  //Save the end date
  if (localStorage.getItem(props.end_date) == null)
    localStorage.setItem(
        props.end_date,
      JSON.stringify(data.date + data.delay)
    );
}}
onComplete={() => {
  if (localStorage.getItem(props.end_date) != null)
    localStorage.removeItem(props.end_date);
    props.enableBtn(false);
}}
/>
  )
}

export default Counter