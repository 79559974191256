import React, { useState,useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import heroImg from '../../assets/images/Mlm-hero-nooverlay.jpg';
import Button from '../../components/Button/Button';
import './LoginPage.scss';
import Spinner from '../../components/Spinner/Spinner';
import Logo from '../../components/Logo/Logo';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import Counter from '../../components/Counter/Counter';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import { Grid } from '@material-ui/core';
const LoginPage=(props)=>{
 const[errorsMessage,seterrorsMessage]=useState();
 const[changePassHtml,setchangePassHtml]=useState();
 const[sendPasswordBtnDisable,setSendPasswordBtnDisable]=useState(true);
 const [validationCodeBtnDisable,setValidationCodeBtnDisable]=useState(true);
 const history = useHistory();
 const {type,key}=useParams();
 console.log(type);
 const [username,setusername]=useState('');
 const [password,setpassword]=useState('');
 const [changepasswordText,setchangepasswordText]=useState('');
 const [changeconfirmpassword,setchangeconfirmpassword]=useState('');
 const [email,setemail]=useState('');
//  || key===undefined 
 const [formtype,setformtype]=useState(type===undefined || key===undefined ?  'login':'changePassword');

 const auth = (e) => {
  e.preventDefault();
  props.onAuth(username, password);
};
const changePassword = (e) => {
  e.preventDefault();
  props.onChangePassword(changepasswordText, key);
};
const getLoginPage=()=>{
  setformtype('login');
};
const sendPasswordMail = (e) => {
  e.preventDefault();
  setSendPasswordBtnDisable(true);
  props.onForgetPassword(email,getLoginPage);
};
const getForgetPasswordPage=(e)=>{
  e.preventDefault();
  setformtype('forgetPassword')
}
const renderForm=(formType)=>{
  switch(formType){
      case 'login':
        loginBtn='Login';
      return(
      <form onSubmit={auth}>
        <input type="text" value={username} placeholder="Username" onChange={(event) => setusername(event.target.value) }/>
        <input type="password" value={password} placeholder="Password" onChange={(event) => setpassword(event.target.value)}/> 
        <Grid container spacing={2}>
        <Grid item style={{width:'50%'}}>
        {errorsMessage}
        </Grid>
        <Grid item style={{width:'50%'}}>
        <Button style={{float:'right',marginTop:0}} click={(e)=>getForgetPasswordPage(e)}><LockResetOutlinedIcon/></Button>
        </Grid>
        </Grid>
        <Button type="submit">{loginBtn}</Button>{/*<p> {this.state.login} | <span>{this.state.password}</span></p>*/}
        </form>);
      case 'forgetPassword':
        loginBtn='Send Password';
      return(<form onSubmit={sendPasswordMail}>
        <input type="email" value={email} placeholder="Email" onChange={(event) => setemail(event.target.value) }/>
        {errorsMessage}
        <Grid container spacing={2}>
        <Grid item style={{width:'50%'}}>
        {changePassHtml}
        <Button disabled={sendPasswordBtnDisable || email===''|| !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)} type="submit">{loginBtn}</Button>{/*<p> {this.state.login} | <span>{this.state.password}</span></p>*/}
        </Grid>
        <Grid item style={{width:'50%'}}>
        <Button style={{float:'right'}} type="button" click={()=>{getLoginPage()}}>Login</Button>{/*<p> {this.state.login} | <span>{this.state.password}</span></p>*/}
        </Grid>
        </Grid>
        </form>);
       case 'changePassword':
        loginBtn='Change Password';
            return(<form onSubmit={changePassword}>
              <input type="password" value={changepasswordText} placeholder="Password" onChange={(event) => setchangepasswordText(event.target.value) }/>
              <input type="password" useRef={changepasswordText}  value={changeconfirmpassword} placeholder="Confirm Password" onChange={(event) => setchangeconfirmpassword(event.target.value) }/>
              {errorsMessage}
              {changepasswordText!==changeconfirmpassword && changepasswordText!=='' && changeconfirmpassword!=='' ? <span style={{ color: 'red' }}>Password mismatch</span>:''}
              <Button disabled={changepasswordText!==changeconfirmpassword|| changepasswordText==''} type="submit">{loginBtn}</Button>{/*<p> {this.state.login} | <span>{this.state.password}</span></p>*/}
              </form>);
    }
  
}
const sendvalidationCodeagain=(e)=>{
  e.preventDefault();
  setValidationCodeBtnDisable(true);
  props.onResendValidation(username);
}
let authRedirect = null;
if (props.isAuthenticated && props.isUserInfo && props.isTransactions) {
  authRedirect = <Redirect to="/"/>;
}

const styles = {
  background: `linear-gradient(
  rgba(0, 64, 128, 0.9), 
  rgba(0, 64, 128, 0.9)
),url(${heroImg}) `,
};

let loginBtn = 'Login';
if (props.auth.loading || props.userLoading) {
  loginBtn = <Spinner/>;
} else {
  loginBtn = 'Login';
}






useEffect(()=>{
  setchangePassHtml(sendPasswordBtnDisable && <Counter end_date={'sendPassword_end_date'} time={60000*0.5} enableBtn={setSendPasswordBtnDisable} />)
  seterrorsMessage(props.auth.error ?  props.auth.error.usernotverified!==undefined ?  <div style={{display:'flex',flexDirection:'column'}}>{ !validationCodeBtnDisable &&<Button style={{margin:0}} disabled={validationCodeBtnDisable} click={(e)=>sendvalidationCodeagain(e)}>Resend activation email</Button>}
  { validationCodeBtnDisable && <Counter end_date={'validationcode_end_date'} time={60000*0.5} enableBtn={setValidationCodeBtnDisable} />}
  <span style={{ color: 'red' }}>{props.auth.error.message}</span></div> : <span style={{ color: 'red' }}>{props.auth.error.message}</span>:null);
},[validationCodeBtnDisable,sendPasswordBtnDisable,props.auth.error]);

return (<>
  {authRedirect}
  <div className="LoginPage">
    <div className="hero" style={styles}>
      <div className="hero__content">
        <h2 className="hero__content__title">Welcome to Earn Easy Hub</h2>
        <p>Join our community that have more than 10000 subscribers and learn new things everyday</p>
        <button onClick={()=>history.push('/LandingPage')} className="waves-effect waves-light btn-large darken-1">Learn More</button>
      </div>
    </div>
    <div className="login-form">
      <div className="login-form__logo">
        <Logo/>
      </div>

      <div className="login-form__form">
        {renderForm(formtype)}
      </div>
      <div className="login-form__signup">
        <p>Don't have account? <span><Link to="/register">Sign up</Link></span></p>
      </div>


    </div>
  </div>
</>
);
} 
const mapStateToProps = state => {
    return {
      isAuthenticated: state.auth.token !== null,
      userId: state.auth.email,
      auth: state.auth,
      userLoading: state.user.loading,
      isUserInfo: state.user.user.id,
      isTransactions:state.user.transactionsLoaded
    };
  }
;


const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, password) => dispatch(actions.auth(username, password)),
    onResendValidation:(username)=>dispatch(actions.sendvalidationCodeagain(username)),
    onChangePassword:(changepasswordText,key)=>dispatch(actions.sendvalidationCodeagain(changepasswordText,key)),
    onForgetPassword:(email,callback)=>dispatch(actions.sendforgetemailagain(email,callback))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
