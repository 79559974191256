import React from 'react'
import { useState,useEffect } from 'react';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import { getPagePermission } from '../../utils/helpers';
import { connect } from 'react-redux';
import CustomCell from './CustomCell';
import { API_URL } from '../../utils/api';
import axios from 'axios';
const CustomDataGrid=(props)=>{
  let token = localStorage.getItem('token');
  const [,setPermission]=useState({
    id: 0,
    id_menu: 0,
    id_role: 0,
    menu:  { id: 0, name: "", accessurl: "" },
    position: 0,
    type: ''
  });
  const [columns,setColumns]=useState([]);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  })
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterValues:[]
  });
  const fetchData = async () => {    
   
    let permissionData=getPagePermission(props.permissions,props.url);
    console.log(permissionData)
    setPermission(old => ({ ...old,  id: permissionData.id,
      id_menu: permissionData.id_menu,
      id_role: permissionData.id_role,
      menu:  { id: permissionData.menu.id, name: permissionData.menu.name, accessurl: permissionData.menu.accessurl },
      position: permissionData.position,
      type: permissionData.type }))
      let columns=props.columns;
      if(columns.filter(x=>x.field==="Action").length===1){
      let index=columns.findIndex(x=>x.field==="Action");
      columns[index]={
        field:"Action",
        headerName: 'Action',
        width: 150,
        renderCell: (params) => <CustomCell onDeleteSuccess={()=>fetchData} msg='Are you sure, you want to delete?' deleteId={params.id} deleteUrl={props.deleteUrl} onEdit={props.onEdit(params.id,false)} permission={permissionData} params={params}  />,
      };
    }
    setColumns(columns);
    setPageState(old => ({ ...old, isLoading: true }))
   
    axios.post(API_URL+props.serverListenerUrl, JSON.stringify({
      page:pageState.page,
      limit:pageState.pageSize,
      filter: filterModel.items,
      quickFilterValue:filterModel.quickFilterValues
    }), {
      headers: {
        Authorization: `JWT ${token}`,'Content-Type':'application/json'
      },
    })
      .then(async(res) => {    
        console.log(res);
        await  setPageState(old => ({ ...old, isLoading: false, data: res.data.data, total: res.data.total }))
      });
  }
  useEffect(() => {
   
    fetchData()
  }, [pageState.page, pageState.pageSize,filterModel,token]);
 
  return (
    <>
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        rowHeight={props.rowHeight!==undefined ?  props.rowHeight:52}
        rowWidth={props.rowWidth!==undefined  ?props.rowWidth:100}
        autoHeight
        rows={pageState.data}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        paginationMode="server"
        filterMode="server"
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { showQuickFilter: true } }}
        onFilterModelChange={setFilterModel}
        // onPageChange={(params) => {
        //   console.log("===params===", params);
        // }}
        onPaginationModelChange={(newPage) => {
          setPageState(old => ({ ...old, page: newPage.page+1,pageSize:newPage.pageSize}))
        }}
        onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
        columns={columns}
      />
</>
  )
}
const mapStateToProps = state => {
  return {
    user: state.user.user,
    bill: state.user.bill,
    transactions: state.user.transactions,
    permissions:state.user.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
   // onGettingTransaction: (token, uId) => dispatch(actions.gettingTransactions(token, uId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomDataGrid);