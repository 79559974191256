import React,{useState} from 'react';
import { useFormik } from 'formik';
import { TextField,Typography } from '@material-ui/core';
import * as yup from 'yup'; // for everything
import { connect } from 'react-redux';
import Button from '../../../components/Button/Button';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TransactionCard from '../../../components/Card/TransactionCard';
import FileUploadButton from '../../../components/Button/FileInputButton';
import { isValidFileType,MAX_FILE_SIZE } from '../../../utils/helpers';
import { useEffect } from 'react';
import TransactionDetailList from '../../../components/DataGrid/TransactionDetailList';
import axios from 'axios';
import { API_URL } from '../../../utils/api';
import PackageCard from '../../../components/Card/PackageCard';
import Grid from '@material-ui/core/Grid';
const AddNewTransaction = (props) => {
  let token = localStorage.getItem('token');
  let [transactions,setTransactionsData]=useState([]);
  const formik = useFormik({
    isInitialValid:false,
    validateOnMount:false,
    initialValues:props.obj,
    validationSchema: yup.object().shape({
      transfer_title:yup.string(),
      id:yup.number().required(),
      authorization_status:yup
      .string('Enter status')
      .required("Please select status"),
      approver_remarks:yup
      .string('Enter status')
      .required("Please remarks"),
      approver_evidence_file: yup
      .mixed()       
      .when('$transfer_title', (transfer_title,schema) => {
        return props.transaction.transfer_title==='Wallet_Withdraw' ? schema.required("Receipt upload required").test("is-valid-type", "Not a valid image type",
        value => { console.log(value); return isValidFileType(value && value.name.toLowerCase(), "approver_evidence_file"); })
      .test("is-valid-size", "Max allowed size is 10MB",
        value => value && value.size <= MAX_FILE_SIZE) : schema;
      })
    }),

    onSubmit: (values, actions) => {
      //  console.log(customParams);
        onFormSubmit(values,actions)
    }
  });
  const onFormSubmit = (data, customParams, form) => {
    var formdata=new FormData();    
    var keys=Object.keys(data);
    keys.forEach((v,i)=>{
      formdata.append(v,data[v]);
    })
    customParams.onSave(formdata,form);

    // async request which may result error
    try {
      // await fetch()
    } catch (e) {
      // handle your error
    }
  }
  useEffect(() => {
    formik.values.id=props.obj.id;
    axios.get(`${API_URL}/transactions/details/${props.obj.id}`,{
      headers: {
        Authorization: `JWT ${token}`, 'Content-Type': 'application/json'
      },
    }).then(async (res) => {
      setTransactionsData(res.data.data);
    });
  }, [props.obj,formik.values]);
  return (
    <div className="DataPAGE__form">
      <form onSubmit={form => { onFormSubmit(formik.values,props, form); }}>
      <TextField  onChange={formik.handleChange} disabled={props.isView} type='hidden' id="id" name="id" value={formik.values.id} />
      <Grid className='padding-12'  container spacing={2}>
     <Grid item xs={12} md={6} sm={12}> 
        <TransactionCard  transaction={props.transaction} />
  </Grid>
  {
  props.transaction.package!==null &&
  <Grid item xs={12} md={6} sm={12}>   
    <div>
        <PackageCard style={{
    height: '100%',
    width:'auto',
    alignItems: 'center'
}} purchaseAction={false} packageRecord={props.transaction.package} alreadypurchased={false} image={`${API_URL}/packagebanners/${props.transaction.package.package_image_file}`} />
    </div>
    </Grid>
}
    </Grid>
        <br />
        <FormControl fullWidth>
          <InputLabel id="authorization_status">Status</InputLabel>
          <Select
            labelId="authorization_status"
            id="authorization_status"
            name='authorization_status'
            label="Status"
            disabled={props.isView}
            value={formik.values.authorization_status}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.authorization_status && Boolean(formik.errors.authorization_status)}
          >
          <MenuItem key={1} value={""}>Select</MenuItem>
          <MenuItem key={2} value={"Approved"}>Approved</MenuItem>
          <MenuItem key={3} value={"Rejected"}>Rejected</MenuItem>
          </Select>
        </FormControl>
        <br />
        <br/>
        {FileUploadButton(formik, 'approver_evidence_file', 'Upload transfer receipt')}
        <br />
        <TextField disabled={props.isView} 
          fullWidth
          id="approver_remarks"
          name="approver_remarks"
          required
          label="Remarks"
          inputProps={{ min: 0 }} 
          type="approver_remarks"
          value={formik.values.approver_remarks}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.approver_remarks && Boolean(formik.errors.approver_remarks)}
          helperText={formik.touched.approver_remarks && formik.errors.approver_remarks}
        />

     

        <br />
        <Typography align='right'>
        <Button  type="submit" disabled={!formik.isValid || props.isView || (props.balance<=0)}><SaveOutlined /> Process</Button>
        </Typography>
      </form>
      <br></br>
      {transactions.length > 0 ? <TransactionDetailList  transactiondetails={transactions} />: <></>}
    </div>
  );
};
const mapStateToProps = state => {
  return {
    user: state.user.user,
    wallet: state.user.wallet,
    transactions: state.user.transactions,
    permissions:state.user.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddNewTransaction);