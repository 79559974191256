import React from 'react'
import { connect } from 'react-redux';
import CustomDataGrid from '../../../components/DataGrid/CustomDataGrid';
import * as actions from '../../../store/actions';
import { useEffect } from 'react';
import { Button } from 'react-materialize';
const UserComplaintList=(props)=>{

  var columns = [
    {
      field: 'id', headerName: 'ID', width: 70
    },
    {
      field: 'id_transaction', headerName: 'Transaction', width: 200,
    },
    {
      field: 'status', headerName: 'status', width: 200,
    },
    {
      field:'',
      headerName:"View Request",
      width: 500,
      renderCell: (params) => { 
        return <Button onClick={()=>props.onEdit(params.row)}>View Request</Button>}
    }
  ]
  useEffect(()=>{
   // props.homePageStart();
  },[]);
  return (
      <CustomDataGrid rowHeight={50}  url="usercomplaints" deleteUrl="/usercomplaints/delete" onEdit={props.onEdit} serverListenerUrl="/usercomplaints"  columns={columns} />   
  )
}
const mapStateToProps = state => {
  return {
    user: state.user.user,
    bill: state.user.bill,
    transactions: state.user.transactions,
    permissions:state.user.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGettingTransaction: (token, uId) => dispatch(actions.gettingTransactions(token, uId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserComplaintList);
