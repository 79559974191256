export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';


export const GETTING_USERINFO_START = 'GETTING_USERINFO_START';
export const GETTING_USER_SUCCESS = 'GETTING_USER_SUCCESS';
export const LOGOUT_USER_INFO = 'LOGOUT_USER_INFO';


export const GETTING_TRANSACTIONS_START = 'GETTING_TRANSACTIONS_START';
export const GETTING_TRANSACTIONS_SUCCESS = 'GETTING_TRANSACTIONS_SUCCESS';
export const GETTING_TRANSACTIONS_FAILED = 'GETTING_TRANSACTIONS_FAILED';


export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';


export const START_DELETING = 'START_DELETING';
export const STOP_DELETING= 'STOP_DELETING';
export const START_DELETE_RESPONSE= 'START_DELETE_RESPONSE';



