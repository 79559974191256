import React, {  useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import UserComplaintList from './UserComplaintList/UserComplaintList';
import { Button } from 'react-materialize';
import { getPagePermission } from '../../utils/helpers';
import AddNewUserComplaint from './AddComplaint/AddNewUserComplaint';
import axios from 'axios';
import { API_URL } from '../../utils/api';
import { getToaster } from '../../utils/helpers';
const UserComplaintsPage=(props)=>{
  let token = localStorage.getItem('token'); 
  const [isAddDisabled,setisAddDisable]=useState(false);
  const [isView,setisView]=useState(false);
  const [adminResponse,setAdminResponse]=useState({
    response:"",
    status:""
  })
  const [activePage,setactivePage]=useState('USERCOMPLAINT_LIST');

  const [obj,setObj]=useState(
      {  id_transaction: 0,
        description: ""}
  );
  const SaveComplaint= async (values,e)=>{
    console.log(values);
    e.preventDefault();
    props.onLoadingStart();

    return  axios.post(`${API_URL}/usercomplaints/add`,values, {
      headers: {
        Authorization: `JWT ${token}`,'Content-Type':'application/json'
      },
    })
      .then(async(res) => {     
        let data=res.data;  
         props.onLoadingStop();
         if (data.success) {
           getToaster('Success','Complaint save status', data.message);
           setactivePage('USERCOMPLAINT_LIST');
         }else{
           getToaster('Error',"Complaint saving error", data.message);
         }
       
      });
  }
  const OnEdit=async(data)=>{
    setactivePage('ADD_NEW_COMPLAINT');
          setObj({...obj,id_transaction:data.id_transaction,description: data.description});
          setAdminResponse({...adminResponse,status:data.status,response:data.response});
          setisView(true);
  }
 
  const add=()=>{
        setObj({
          id_transaction: 0,
          description: ""
        });
        setisView(false);
        setactivePage('ADD_NEW_COMPLAINT');
  }

  

  useEffect(()=>{
    if (token == null) {
       props.history.replace('/login');
    }
    let permissionData=getPagePermission(props.permissions,"usercomplaints");
    console.log(permissionData); 
    if(activePage==='USERCOMPLAINT_LIST'){
      setisAddDisable(false);
    }else{
      setisAddDisable(true);
    }
  },[activePage,props.history,props.permissions,token]);


  
    return (
      <>
        {props.user.id ?
     <div className="DataPAGE">
     <div className="DataPAGE__add">
              <nav>
                <ul>
                <li><Button disabled={isAddDisabled} onClick={() => add()}>Add Complaint</Button></li>
                  <li><Button onClick={() => setactivePage('USERCOMPLAINT_LIST')}>COMPLAINTS</Button></li>
                </ul>
              </nav>
            </div>
            {activePage === 'USERCOMPLAINT_LIST' ?
  <UserComplaintList onEdit={OnEdit} /> :  <AddNewUserComplaint adminResponse={adminResponse} isView={isView} SaveComplaint={SaveComplaint} obj={obj}   />}
          </div> : null}
      </>
    );
}



const mapDispatchToProps = dispatch => {
  return {
    onLoadingStart: () => dispatch(actions.startLoading()),
    onLoadingStop: () => dispatch(actions.stopLoading()),
  };
};

const mapStateToProps = state => {
  return {
    user: state.user.user, 
    permissions:state.user.permissions
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserComplaintsPage);
