import React, { useEffect, useState } from 'react'
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { API_URL } from '../../utils/api';
const TransactionDetailCard=({user_evidence_file,amount_money,created_date_time,id,handleShowDialog})=>{
 
  return (
    <Card key={id} className="col-3 axios-items">
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt={user_evidence_file}
                  height="140"
                  image={`${API_URL}/paymentreceipts/${user_evidence_file}`}
                  onClick={()=>handleShowDialog(user_evidence_file,amount_money,created_date_time)}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                   Amount Paid: {amount_money}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                   Posted Date: {created_date_time}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
  )
}

export default TransactionDetailCard