import React, {  useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { getToaster } from '../../utils/helpers';
import { Button } from 'react-materialize';
import { API_URL } from '../../utils/api';
import axios from 'axios';
import { getPagePermission } from '../../utils/helpers';
import AddNewComplaint from './AddNewComplaint/AddNewComplaint';
import ComplaintList from './ComplaintList/ComplaintList';
const ComplaintsPage=(props)=>{
  let token = localStorage.getItem('token'); 
  const [isView,]=useState(false);
  const [activePage,setactivePage]=useState('COMPLAINT_LIST');

  const [obj,setObj]=useState({
    id:0,
    id_transaction:0,
    description:"",
    status:"",
    response:"",
  });
  const SaveComplaint= async (values,e)=>{
    e.preventDefault();
    props.onLoadingStart();

    return  axios.post(`${API_URL}/complaints/add`,values, {
      headers: {
        Authorization: `JWT ${token}`,'Content-Type':'application/json'
      },
    })
      .then(async(res) => {     
        let data=res.data;  
         props.onLoadingStop();
         if (data.success) {
           getToaster('Success','Complaint save status', data.message);
           setactivePage('COMPLAINT_LIST');
         }else{
           getToaster('Error',"Complaint saving error", data.message);
         }
       
      });
  }
  const OnEdit=async(row)=>{
          setObj(row);
          setactivePage('ADD_NEW_COMPLAINT');
  }
 


  

  useEffect(()=>{
    if (token == null) {
       props.history.replace('/login');
    }
 
    const changePage = (page) => {
      setactivePage(page);
      if(activePage==="COMPLAINT_LIST"){
      //  setisAddDisable(true);
      }
      else{
        setObj({
          id:0,
    id_transaction:0,
    description:"",
    status:"",
    response:"",
        });
     //   setisAddDisable(false);
      }
    };
    let permissionData=getPagePermission(props.permissions,"usercomplaints");
    console.log(permissionData); 
      changePage(activePage);
  },[activePage,props.history,props.permissions,token]);


  
    return (
      <>
        {props.user.id ?
     <div className="DataPAGE">
     <div className="DataPAGE__add">
              <nav>
                <ul>
                {/* <li><Button onClick={() => setactivePage('REQUEST_TASK')}>Attempt Task</Button></li> */}
                  <li><Button onClick={() => setactivePage('COMPLAINT_LIST')}>COMPLAINTS</Button></li>
                </ul>
              </nav>
            </div>
            {activePage === 'COMPLAINT_LIST' ?
  <ComplaintList onEdit={OnEdit} /> :  <AddNewComplaint isView={isView} SaveComplaint={SaveComplaint} obj={obj}   />}
          </div> : null}
      </>
    );
}



const mapDispatchToProps = dispatch => {
  return {
    onLoadingStart: () => dispatch(actions.startLoading()),
    onLoadingStop: () => dispatch(actions.stopLoading()),
  };
};

const mapStateToProps = state => {
  return {
    user: state.user.user, 
    permissions:state.user.permissions
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintsPage);
