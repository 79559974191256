import React, {  useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import UserTaskList from './UserTaskList/UserTaskList';
import { Button } from 'react-materialize';
import { getPagePermission } from '../../utils/helpers';
import AddNewUserTask from './AddTask/AddNewUserTask';
const UserTasksPage=(props)=>{
  let token = localStorage.getItem('token'); 
  const [isView,]=useState(false);
  const [packageId,setpackageId]=useState(0);
  const [taskId,settaskId]=useState(0);
  const [activePage,setactivePage]=useState('USERTASK_LIST');

  const [obj,setObj]=useState([
      {id:0,name:"",url:"",isCompleted:false}
  ]);
  const AddTask= async (values,e)=>{
  //   e.preventDefault();
  //   console.log(values);
  //  props.onLoadingStart();

  //   return  axios.post(`${API_URL}/usertasks/add`,values, {
  //     headers: {
  //       Authorization: `JWT ${token}`,'Content-Type':'application/json'
  //     },
  //   })
  //     .then(async(res) => {     
  //       let data=res.data;  
  //        props.onLoadingStop();
  //        if (data.success) {
      //     getToaster('Success','User task status', data.message);
            setactivePage('USERTASK_LIST');
      //    }else{
      //      getToaster('Error',"User task saving error", data.message);
      //    }
       
      // });
  }

 
  const getTaskPage=(row)=>{
   
    let details=row.task.taskdetails;
    let userTask=row.task.usertasks.filter(x=>x.id_package===row.package.id)[0];
    console.log(userTask);
    if(userTask){
    let usertaskdetails=userTask.usertaskdetails;
    console.log()
    let playing=false;
    details.forEach((v,i)=>{
        let exist=usertaskdetails.filter(x=>x.id_taskdetail===v.id).length > 0;
        if(exist) { details[i]['isCompleted']=true;details[i]['isPlaying']=false;}else{details[i]['isCompleted']=false;if(playing===false){
          playing=true;
          details[i]['isPlaying']=true;
        }else{
          details[i]['isPlaying']=false;
        }}
    });
   }else{
    let playing=false;
    details.forEach((v,i)=>{
      if(playing){
        details[i]['isCompleted']=false;
        details[i]['isPlaying']=false;
      }else{
        playing=true;
        details[i]['isCompleted']=false;
        details[i]['isPlaying']=true;
      }
    });
   }
    setpackageId(row.package.id);
    settaskId(row.task.id);
    setactivePage("REQUEST_TASK");
    setObj(details);
  }
  
 


  

  useEffect(()=>{
    if (token == null) {
       props.history.replace('/login');
    }
 
    const changePage = (page) => {
      setactivePage(page);
      if(activePage==="USERTASK_LIST"){
      //  setisAddDisable(true);
      }
      else{
        setObj({
          id_paymentmethod:"",
          user_receiving_number:"",
          amount_money:0,
        });
     //   setisAddDisable(false);
      }
    };
    let permissionData=getPagePermission(props.permissions,"usertasks");
    console.log(permissionData); 
      changePage(activePage);
  },[activePage,props.history,props.permissions,token]);


  
    return (
      <>
        {props.user.id ?
     <div className="DataPAGE">
     <div className="DataPAGE__add">
              <nav>
                <ul>
                {/* <li><Button onClick={() => setactivePage('REQUEST_TASK')}>Attempt Task</Button></li> */}
                  <li><Button onClick={() => setactivePage('USERTASK_LIST')}>TASKS</Button></li>
                </ul>
              </nav>
            </div>
            {activePage === 'USERTASK_LIST' ?
  <UserTaskList onTask={getTaskPage} /> :  <AddNewUserTask packageId={packageId} taskId={taskId} isView={isView} AddTask={AddTask} obj={obj}   />}
          </div> : null}
      </>
    );
}



const mapDispatchToProps = dispatch => {
  return {
    onLoadingStart: () => dispatch(actions.startLoading()),
    onLoadingStop: () => dispatch(actions.stopLoading()),
  };
};

const mapStateToProps = state => {
  return {
    user: state.user.user, 
    permissions:state.user.permissions
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTasksPage);
