import React from 'react';
import ReactDOM from "react-dom/client";

import './index.css';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';

import {BrowserRouter} from 'react-router-dom';

import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import {Provider} from 'react-redux';
import {reducer as toastrReducer} from 'react-redux-toastr'
import thunk from 'redux-thunk';
import authReducer from './store/reducers/auth'
import userReducer from './store/reducers/user'
import deletingReducer from './store/reducers/delete'
import loadingReducer from './store/reducers/loading'

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({
  auth:authReducer,
  user:userReducer,
  delete:deletingReducer,
  loading:loadingReducer,
  toastr: toastrReducer // <- Mounted at toastr.
});

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));
const root=ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
