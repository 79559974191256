import React, {  useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import TaskList from './TaskList/TaskList';
import { getToaster } from '../../utils/helpers';
import { Button } from 'react-materialize';
import { API_URL } from '../../utils/api';
import axios from 'axios';
import { getPagePermission } from '../../utils/helpers';
import AddNewTask from './AddTask/AddNewTask';
const TasksPage=(props)=>{
  let token = localStorage.getItem('token'); 
  let [data_text,setdata_text]=useState('Add new'); 
  const [isView,setisView]=useState(false);
  const [isEdit,setisEdit]=useState(false);
  const [isAddDisable,setisAddDisable]=useState(false);
  const [activePage,setactivePage]=useState('TASK_LIST');

  const [obj,setObj]=useState({
    id: 0,
    title:"",
    duration:1,
    id_package:[],
    status:"Disable",
    taskdetails:[    { name: "", url:"" }]
  });
  const OnEdit=async(id,isDataView)=>{
    isDataView  ? setisView(true) :setisView(false);
    props.onLoadingStart();
    await axios.get(`${API_URL}/tasks/edit?id=${id}`,{
      headers: {
        Authorization: `JWT ${token}`
      }
    }
    ).then(async(res)=>{
       let data=res.data;  
       console.log(data);
        props.onLoadingStop();
          data.data['id_package']=data.data.taskpackages.length > 0 ? data.data.taskpackages.map(x=>x.id_package):[];
          setObj(data.data);
          isDataView ? setdata_text("View"):setdata_text('Edit');
          setisEdit(true);
          setactivePage('ADD_TASK');
      })
  }

  const SaveTask= async (values,e)=>{
    e.preventDefault();
    console.log(values);
   props.onLoadingStart();

    return  axios.post(`${API_URL}/tasks/${obj.id===0 ? 'add':'edit'}`,values, {
      headers: {
        Authorization: `JWT ${token}`,'Content-Type':'application/json'
      },
    })
      .then(async(res) => {     
        let data=res.data;  
         props.onLoadingStop();
         if (data.success) {
           getToaster('Success','Task status', data.message);
           setactivePage('TASK_LIST');
         }else{
           getToaster('Error',"Task saving error", data.message);
         }
       
      });
  }

 
  const getAddPage=()=>{
    setactivePage("ADD_TASK");
  }
  
 


  

  useEffect(()=>{
    if (token == null) {
       props.history.replace('/login');
    }
 
    const changePage = (page) => {
      setactivePage(page);
      if(activePage==="TASK_LIST"){
      //  setisAddDisable(true);
      setObj({
        id: 0,
  title:"",
  duration:1,
  id_package:[],
  status:"Disable",
  taskdetails:[    { name: "", url:"" }]
      });
      }
      else{
        setdata_text("Add new");
        setisEdit(false); 
        setisView(false);
     //   setisAddDisable(false);
      }
    };
    let permissionData=getPagePermission(props.permissions,"tasks");
    console.log(permissionData); 
      permissionData!==undefined  && setisAddDisable((permissionData.type==="All" || permissionData.type.includes("Add")));
      changePage(activePage);
      isEdit && setisAddDisable(true);
  },[activePage,isEdit,props.history,props.permissions,token]);


  
    return (
      <>
        {props.user.id ?
     <div className="DataPAGE">
     <div className="DataPAGE__add">
              <nav>
                <ul>
                <li><Button disabled={!isAddDisable} onClick={() => setactivePage('ADD_TASK')}>{data_text} task</Button></li>
                  <li><Button onClick={() => setactivePage('TASK_LIST')}>Tasks</Button></li>
                </ul>
              </nav>
            </div>
            {activePage === 'TASK_LIST' ?
  <TaskList  onDeleteSuccess={()=>getAddPage} onEdit={()=>OnEdit}/> :  <AddNewTask isView={isView} onSave={SaveTask} obj={obj}   />}
          </div> : null}
      </>
    );
}



const mapDispatchToProps = dispatch => {
  return {
    onLoadingStart: () => dispatch(actions.startLoading()),
    onLoadingStop: () => dispatch(actions.stopLoading()),
  };
};

const mapStateToProps = state => {
  return {
    user: state.user.user, 
    permissions:state.user.permissions
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksPage);
