import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { TextField, Typography } from '@material-ui/core';
import * as yup from 'yup'; // for everything
import { useState } from 'react';
import Button from '../../../components/Button/Button';
import Shop2OutlinedIcon from '@mui/icons-material/Shop2Outlined';
import FileUploadButton from '../../../components/Button/FileInputButton';
import { isValidFileType,MAX_FILE_SIZE } from '../../../utils/helpers';
import Select from '@mui/material/Select';
import axios from 'axios';
import { API_URL } from '../../../utils/api';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import PackageCard from '../../../components/Card/PackageCard';
import {Grid} from '@material-ui/core';
import TransactionDetailList from '../../../components/DataGrid/TransactionDetailList';
const AddNewUserPackage = (props) => {
  let token = localStorage.getItem('token');
  let [paymentMethods, setpaymentMethods] = useState([]);
  let [transactions,setTransactionsData]=useState([]);
  const [initialValues]=useState(props.obj);
  console.log(initialValues);
  const formik = useFormik({
    isInitialValid:false,
    validateOnMount:false,
    initialValues:initialValues,
    validationSchema: yup.object().shape({
      id_paymentmethod:yup
      .string('Enter payment method')
      .required("Please select payment method"),
      user_evidence_file: yup
        .mixed()
        .required("Please upload the receipt")
        .test("is-valid-type", "Not a valid image type",
          value => { console.log(value); return isValidFileType(value && value.name.toLowerCase(), "user_evidence_file"); })
        .test("is-valid-size", "Max allowed size is 10MB",
          value => value && value.size <= MAX_FILE_SIZE)
    }),

    onSubmit: (values, actions) => {
      //  console.log(customParams);
        onFormSubmit(values,actions)
    }
  });
  const onFormSubmit = (data, customParams, form) => {
    var formdata=new FormData();    
    var keys=Object.keys(data);
    keys.forEach((v,i)=>{
      formdata.append(v,data[v]);
    })
    customParams.onSave(formdata,form);

    // async request which may result error
    try {
      // await fetch()
    } catch (e) {
      // handle your error
    }
  }
  useEffect(() => {
    formik.values.id_package=props.obj.id_package;
    formik.values.id=props.obj.id;
    axios.get(`${API_URL}/paymentmethods`, {
      headers: {
        Authorization: `JWT ${token}`, 'Content-Type': 'application/json'
      },
    })
      .then(async (res) => {
        setpaymentMethods(res.data.data);
      });
    axios.get(`${API_URL}/transactions/details/${props.obj.id}`,{
      headers: {
        Authorization: `JWT ${token}`, 'Content-Type': 'application/json'
      },
    }).then(async (res) => {
      setTransactionsData(res.data.data);
    });
  }, [token,props.obj,formik.values]);

  return (
    <div className="DataPAGE__form">
      <form onSubmit={form => { onFormSubmit(formik.values,props, form); }}>
      <TextField  onChange={formik.handleChange} disabled={props.isView} type='hidden' id="id" name="id" value={formik.values.id} />
        
      <TextField  onChange={formik.handleChange} disabled={props.isView} type='hidden' id="id_package" name="id_package" value={formik.values.id_package} />
      <Grid container spacing={2}>
      <Grid item xs={12}>
        <PackageCard purchaseAction={false} packageRecord={props.packageRecord} onPurchase={() => props.onPurchase(props.paymentRecord)} alreadypurchased={false} image={`${API_URL}/packagebanners/${props.packageRecord.package_image_file}`} />
        <br/>
       </Grid>
       <Grid item xs={12}>
        <TextField disabled={props.isView} type='hidden' id="id_paymentmethod" name="id_paymentmethod" value={formik.values.id_paymentmethod} />
     
        <FormControl fullWidth>
          <InputLabel id="id_paymentmethod">Payment Method</InputLabel>
          <Select
            labelId="id_paymentmethod"
            id="id_paymentmethod"
            name='id_paymentmethod'
            label="Payment Method"
            disabled={props.isView}
            value={formik.values.id_paymentmethod}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.id_paymentmethod && Boolean(formik.errors.id_paymentmethod)}
          >
            {
              paymentMethods.map((v, i) => {
                console.log(v);
                return <MenuItem key={i} value={v.id}>{v.name + " " + v.associatednumber}</MenuItem>
              })
            }
          </Select>
        </FormControl>
         <br/>
         <br/>
        {FileUploadButton(formik, 'user_evidence_file', 'Upload payment slip')}
        <br />
          <Typography align='right'>
         <Button align="right" type="submit" disabled={!formik.isValid || props.isView }><Shop2OutlinedIcon /> Purchase</Button>
          </Typography>
            </Grid>
      </Grid>
      </form>
      <br></br>
      {transactions.length > 0 ? <TransactionDetailList  transactiondetails={transactions} />: <></>}
    </div>
  );
};

export default AddNewUserPackage;
